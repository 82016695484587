import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper, Typography, Button, Grid, Box, Modal, Snackbar, Alert, IconButton
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Navbar from './Navbar';
import UserInbox from './UserInbox';
import UserMenu from './UserMenu';
import { UserContext } from '../UserContext';
import TicketModal from './TicketModal';

const UserProfile = () => {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleLogout = () => {
    logout();
    navigate('/login');
    setSnackbar({ open: true, message: 'Logged out successfully', severity: 'success' });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <Navbar />
      <Paper style={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: 64, padding: 20, backgroundColor: '#1B277D', color: '#FFF', overflow: 'hidden' }}>
        <div style={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
          <Box sx={{ width: { xs: 0, sm: '200px' }, overflowY: 'auto' }}>
            <Typography variant="h6" style={{ padding: '16px' }}>
              Welcome, {user?.name}
            </Typography>
            <UserMenu />
          </Box>
          <Box sx={{ flex: 1, overflowY: 'auto' }}>
            <Paper elevation={3} style={{ margin: '16px', padding: '16px', flexGrow: 1, overflow: 'auto', height: 'calc(100vh - 180px - 32px)', backgroundColor: '#b2dfdb' }}>
              <UserInbox />
            </Paper>
          </Box>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: '#00e5ff' }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: '#fff' }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography color="#00e5ff" style={{ textAlign: 'center' }}>
              Do you need any support?
            </Typography>
            <Button variant="contained" style={{ margin: 'auto', display: 'block', backgroundColor: '#4caf50' }} onClick={handleOpen}>
              Talk To Us
            </Button>
            <Modal open={openModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <TicketModal open={openModal} onClose={handleClose} />
            </Modal>
          </Grid>
          <Grid item xs={12} sm={4} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleLogout} sx={{ fontSize: 40, color: 'primary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)', color: 'secondary.main' } }}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserProfile;
