import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  Modal,
  useTheme,
  useMediaQuery,
  Container,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Tab,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { Alert } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconMarquee from "../../components/IconMarquee";
import Navbar from "../../components/Navbar";
import SocialMediaLinks from "../../components/SocialMediaLinks";
import FlipAnimation from "../../components/FlipAnimation";
import "../../components/SliderStyles.css";
import SignIn from "../SignIn";
import UserSignIn from "../../components/UserSignIn";
import SignUp from "../SignUp";
import { signInWithGoogle } from "../../components/signInWithGoogle";
import { signInWithFacebook } from "../../components/FacebookSignIn";
import QuickLinks from "../../components/QuickLinks";
import OurFeatures from "../../components/OurFeatures";
import videoSource from "../../assets/video.mp4";
import therapy from "../../assets/images/therapy.png";
import Selectors from "../../components/Selectors";
import Panel from "../../components/Panel";
import GeneralQuestionnaire from "../../components/GeneralQuestionnaire";
import Map from "../../components/Map";

const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const testimonials = [
  {
    quote:
      "Using this platform has revolutionized the way our team approaches development. The intuitive design and robust features made it easy for us to integrate our projects seamlessly.",
    author: "Jamie Lannister",
    position: "Lead Developer",
    company: "Westeros Inc.",
  },
  {
    quote:
      "I was amazed by the level of support and community engagement I found here. It's not just about the tools; it's the people behind them that make the difference.",
    author: "Sansa Stark",
    position: "Product Manager",
    company: "North Enterprises",
  },
  {
    quote:
      "Transitioning to using these services has been a game changer for our productivity and workflow efficiency. Highly recommend to any teams looking to scale their development efforts.",
    author: "Tyrion Lannister",
    position: "CTO",
    company: "Lannister Solutions",
  },
  {
    quote:
      "As a freelance developer, finding a platform that I can rely on for all my projects has been a challenge. This service has met all my needs and exceeded my expectations in terms of usability and performance.",
    author: "Arya Stark",
    position: "Freelance Developer",
    company: "Independent",
  },
  {
    quote:
      "The attention to detail and the commitment to open-source principles have made this my go-to platform for development. It's rare to find such a powerful tool that's also community-driven.",
    author: "Daenerys Targaryen",
    position: "Software Engineer",
    company: "DragonTech",
  },
];

const cardData = [
  {
    title: "About Us",
    content: "We provide access to the best therapy services in Kenya.",
    image:
      "https://images.unsplash.com/photo-1600091474842-83bb9c05a723?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    action: "Partner With Us",
    actionLink: "/partner",
    cardLink: "/about-us",
  },
  {
    title: "Mental Health",
    content: "Discover our mental health services and resources here.",
    image: "https://source.unsplash.com/random?mental-health",
    action: "Join Our Community",
    actionLink: "/community",
    cardLink: "/mental-health",
  },
  {
    title: "Service Providers",
    content:
      "For healthcare service providers and caregivers across Kenya.",
    image: "https://source.unsplash.com/random?general-health",
    action: "Resource Centers",
    actionLink: "/resource-center",
    cardLink: "/service-providers",
  },
];

const TherapyServices = [
  {
    name: "Career and Sports Therapy",
    description: "Navigate Your Professional Path with Confidence.",
  },
  {
    name: "Performance Enhancement Therapy",
    description:
      "For musicians, actors, public speakers, or professionals striving for excellence.",
  },
  {
    name: "Group Therapy",
    description: "Shared Experiences, Collective Growth.",
  },
  {
    name: "Art and Music Therapy",
    description: "Express Yourself. Heal. Create. Connect.",
  },
  {
    name: "Couple and Family Therapy",
    description: "Strengthen Bonds. Resolve Conflicts. Build Harmony.",
  },
  {
    name: "Holistic Therapy",
    description: "Harmonize Body, Mind, and Spirit.",
  },
];

const HomePage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  const navigate = useNavigate();

  const navbarHeight = 64;

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openSocialModal, setOpenSocialModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [error, setError] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [resourceCenters, setResourceCenters] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Fetch questions from the backend
    axios
      .get("https://therapy-api.accentrixcloud.com/questions")
      .then((response) => {
        setQuestions(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the questions!", error);
      });
  }, []);

  useEffect(() => {
    // Fetch resource centers
    axios
      .get("https://therapy-api.accentrixcloud.com/api/resource-centres")
      .then((response) => {
        setResourceCenters(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the resource centers!", error);
      });

    // Get user location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (error) => console.error("Error getting user location:", error)
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleOpen = () => setOpenModal(true);
  const handleSocialOpen = () => setOpenSocialModal(true);
  const handleSocialClose = () => setOpenSocialModal(false);

  const handleLoginOpen = () => setOpenLoginModal(true);
  const handleLoginClose = () => setOpenLoginModal(false);

  const handleClose = () => setOpenModal(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleShowQuestionnaire = () => setShowQuestionnaire(true);

  const handleSignInWithGoogle = async () => {
    try {
      const user = await signInWithGoogle();
      console.log(user);

      const backendResponse = await axios.get(
        "http://cvs.accentrixcloud.com/api/accounts"
      );
      const accounts = backendResponse.data;

      const regex = new RegExp(user.email);
      const matchingAccount = accounts.find((account) =>
        regex.test(account.email)
      );

      if (matchingAccount) {
        if (matchingAccount.is_admin) {
          navigate("/macrobian", { state: { res_admin: matchingAccount } });
        } else if (matchingAccount.is_subscriber) {
          navigate("/home", {
            state: { res_serviceProvider: matchingAccount },
          });
        } else if (matchingAccount.is_active) {
          navigate("/user-profile", { state: { res_patient: matchingAccount } });
        }
      } else {
        alert("No matching account found or account is inactive.");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      alert(`An error occurred while signing in: ${error.message}`);
    }
  };

  const handleSignInWithFacebook = async () => {
    try {
      const user = await signInWithFacebook();
      console.log(user);

      const backendResponse = await axios.get(
        "http://cvs.accentrixcloud.com/api/accounts"
      );
      const accounts = backendResponse.data;

      const regex = new RegExp(user.email, "i");
      const matchingAccount = accounts.find((account) =>
        regex.test(account.email)
      );

      if (matchingAccount) {
        if (matchingAccount.is_admin) {
          navigate("/macrobian", { state: { res_admin: matchingAccount } });
        } else if (matchingAccount.is_subscriber) {
          navigate("/home", {
            state: { res_serviceProvider: matchingAccount },
          });
        } else if (matchingAccount.is_active) {
          navigate("/user-profile", { state: { res_patient: matchingAccount } });
        }
      } else {
        alert("No matching account found or account is inactive.");
      }
    } catch (error) {
      console.error("Sign in error with Facebook:", error);
      alert(`An error occurred while signing in with Facebook: ${error.message}`);
    }
  };

  const handleSignInWithEmail = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    try {
      const response = await axios.get(
        "https://asset-management.accentrixcloud.com/api/accounts"
      );
      const accounts = response.data;

      const regex = new RegExp(email);
      const matchingAccount = accounts.find((account) => account.email === email);
      console.log(matchingAccount);
      if (matchingAccount) {
        if (matchingAccount.is_admin) {
          navigate("/macrobian", { state: { res_admin: matchingAccount } });
        } else if (matchingAccount.is_subscriber) {
          navigate("/home", {
            state: { res_serviceProvider: matchingAccount },
          });
        } else if (matchingAccount.is_active) {
          navigate("/user-profile", { state: { res_patient: matchingAccount } });
        }
      } else {
        alert("No matching account found or account is inactive.");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      alert(`An error occurred while signing in: ${error.message}`);
    }
  };

  const bannerStyle = {
    display: "flex",
    flexDirection: "column",
    marginTop: `${navbarHeight}px`,
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: 'url("https://source.unsplash.com/random?therapy")',
    position: "relative",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    zIndex: 2,
    color: "white",
    fontSize: "48px",
    position: "relative",
  };

  const subtitle = {
    zIndex: 2,
    color: "white",
    fontSize: "28px",
    position: "relative",
  };

  // Handle input change
  const handleChange = (event) => {
    setAnswers({ ...answers, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpenConfirmDialog(true);
  };

  const handleConfirmSubmit = async () => {
    setOpenConfirmDialog(false);
    setLoading(true);

    // Submit user info to /users endpoint first
    let userId = null;

    try {
      const userResponse = await axios.post(
        "https://therapy-api.accentrixcloud.com/users",
        {
          name: answers.name,
          email: answers.email,
        }
      );
      userId = userResponse.data.user_id;
    } catch (error) {
      console.error(
        "Error submitting user info:",
        error.response?.data?.error || error.message
      );
      setError(
        "Error submitting user info: " +
          (error.response?.data?.error || error.message)
      );
      setSnackbarMessage(
        "Error submitting user info: " +
          (error.response?.data?.error || error.message)
      );
      setSnackbarOpen(true);
      setLoading(false);
      return; // Exit the function if user creation fails
    }

    // Prepare responses data
    const responses = Object.keys(answers)
      .filter((key) => key !== "name" && key !== "email")
      .map((key) => {
        const questionId = key.split("_")[1]; // Assuming the question ID is part of the key
        return {
          user_id: userId,
          question_id: parseInt(questionId),
          answer: answers[key],
        };
      });

    console.log("Posting responses to /responses:", responses);

    // Submit responses to /responses endpoint
    try {
      await axios.post("https://therapy-api.accentrixcloud.com/responses", {
        responses,
      });
      setOpenSubmitDialog(true); // Show submission dialog on success
    } catch (error) {
      console.error("Error submitting responses:", error);
      setError(
        "Error submitting responses: " +
          (error.response?.data?.error || error.message)
      );
      setSnackbarMessage(
        "Error submitting responses: " +
          (error.response?.data?.error || error.message)
      );
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSubmitDialog = () => {
    setOpenSubmitDialog(false);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleNextTab = () => {
    if (tabIndex < 3) {
      setTabIndex(tabIndex + 1);
    }
  };

  const handleBackTab = () => {
    if (tabIndex > 0) {
      setTabIndex(tabIndex - 1);
    }
  };

  const textFieldStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: "black",
    marginBottom: "8px",
  };

  const [activeID, setActiveID] = useState(0);
  const [wrapperStyle, setWrapperStyle] = useState({
    backgroundImage: `url('https://source.unsplash.com/random?therapy')`,
    backgroundSize: "cover",
  });
  const [panelStyle, setPanelStyle] = useState({ background: "#242846" });
  const [buttonStyle, setButtonStyle] = useState({ color: "#ffffff" });

  const changeActive = (id) => {
    setActiveID(id);
    setWrapperStyle({
      backgroundImage: `url('https://source.unsplash.com/random?therapy')`,
      backgroundSize: "cover",
    });
    setPanelStyle({ background: "#242846" });
  };

  const buttonColour = () => {
    setButtonStyle((currentStyle) => ({
      color: currentStyle.color === "#ffffff" ? "#242846" : "#ffffff",
    }));
  };

  const renderQuestions = (questions) => {
    return questions.map((question, index) => {
      if (question.options) {
        const optionsArray = JSON.parse(question.options);
        return (
          <Box key={index}>
            <FormLabel style={{ color: "#4caf50" }}>
              {question.question_text}
            </FormLabel>
            <RadioGroup
              name={`question_${question.question_id}`}
              value={answers[`question_${question.question_id}`] || ""}
              onChange={handleChange}
              row
              style={{ color: "black", marginBottom: "8px" }}
            >
              {optionsArray.map((option, i) => (
                <FormControlLabel
                  key={i}
                  value={option.trim()} // Ensure there are no leading/trailing spaces
                  control={<Radio />}
                  label={option.trim()}
                />
              ))}
            </RadioGroup>
          </Box>
        );
      } else {
        return (
          <TextField
            key={index}
            fullWidth
            label={question.question_text}
            variant="filled"
            InputProps={{
              style: { backgroundColor: "transparent", color: "black" },
            }}
            InputLabelProps={{
              style: { color: "#4caf50" },
            }}
            name={`question_${question.question_id}`}
            value={answers[`question_${question.question_id}`] || ""}
            onChange={handleChange}
          />
        );
      }
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div style={{ maxHeight: "100vh" }}>
      <Navbar />
      <div style={bannerStyle}>
        <div style={overlayStyle}></div>
        <Typography variant="h3" style={textStyle}>
          Therapy for Everyone
        </Typography>
        <Typography variant="h5" style={subtitle}>
          A sanctuary for the soul, a workshop for the mind, and a training
          ground for the body
        </Typography>
        <Button
          variant="contained"
          onClick={handleSocialOpen}
          sx={{
            backgroundColor: "#4caf50",
          }}
        >
          Sign In
        </Button>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-around",
          bgcolor: "#e1f5fe",
          color: "#fff",
          overflow: "hidden",
        }}
      >
        <Box
          flex={2}
          sx={{
            minWidth: 300,
            padding: theme.spacing(2),
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            {TherapyServices.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ backgroundColor: "#e0f2f1" }}>
                  <CardActionArea onClick={handleOpenModal}>
                    <CardContent>
                      <Typography variant="h5" component="h2" color="#4caf50">
                        {service.name}
                      </Typography>
                      <Typography color="black">
                        {service.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Modal open={openModal} onClose={handleCloseModal}>
            <Container
              maxWidth="sm"
              style={{
                backgroundColor: "#e1f5fe",
                color: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                marginTop: "50px",
                maxHeight: "80vh",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: "#4caf50" }}
              >
                Macrobian Therapy Assessment
              </Typography>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="questionnaire tabs"
                style={{ marginBottom: "20px" }}
              >
                <Tab label="Personal Questions" />
                <Tab label="Lifestyle Questions" />
                <Tab label="Mental Health History" />
                <Tab label="Register & Submit" />
              </Tabs>
              <form onSubmit={handleSubmit}>
                {tabIndex === 0 && (
                  <Box>
                    {renderQuestions(
                      questions.filter((q) => q.category === "personal")
                    )}
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    {renderQuestions(
                      questions.filter((q) => q.category === "lifestyle")
                    )}
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    {renderQuestions(
                      questions.filter(
                        (q) => q.category === "mentalHealthHistory"
                      )
                    )}
                  </Box>
                )}
                {tabIndex === 3 && (
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "45ch" },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      required
                      id="name"
                      label="Contact Name"
                      name="name"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      id="email"
                      label="Email"
                      name="email"
                      type="email"
                      onChange={handleChange}
                    />
                    <Box sx={{ position: "relative" }}>
                      <Button
                        variant="contained"
                        onClick={handleConfirmSubmit}
                        style={{
                          mt: 2,
                          display: "block",
                          backgroundColor: "#4caf50",
                          alignSelf: "center",
                        }}
                        disabled={loading}
                      >
                        Submit
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                )}
              </form>
              <Box
                display="flex"
                justifyContent="space-between"
                mt={2}
                position="fixed"
                left="50%"
                style={{ transform: "translateX(-50%)", bottom: "20px" }}
              >
                {tabIndex > 0 && (
                  <Button
                    variant="contained"
                    onClick={handleBackTab}
                    style={{
                      backgroundColor: "#4caf50",
                      color: "#ffffff",
                      marginRight: "10px",
                    }}
                    startIcon={<ArrowBackIcon />}
                  />
                )}
                {tabIndex < 3 && (
                  <Button
                    variant="contained"
                    onClick={handleNextTab}
                    style={{
                      backgroundColor: "#4caf50",
                      color: "#ffffff",
                      marginLeft: "10px",
                    }}
                    endIcon={<ArrowForwardIcon />}
                  />
                )}
              </Box>
            </Container>
          </Modal>
          <Dialog
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
          >
            <DialogTitle>{"Confirm Submission"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to submit this questionnaire?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirmDialog(false)}>No</Button>
              <Button onClick={handleConfirmSubmit} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openSubmitDialog}
            onClose={handleCloseSubmitDialog}
          >
            <DialogTitle>{"Submission Successful"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Your data has been submitted. Please wait for a response.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseSubmitDialog}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openErrorDialog}
            onClose={() => setOpenErrorDialog(false)}
          >
            <DialogTitle>{"Error"}</DialogTitle>
            <DialogContent>
              <DialogContentText>{error}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenErrorDialog(false)}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity="error">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
        <Box
          flex={1}
          sx={{
            padding: theme.spacing(2),
            overflowY: "auto",
          }}
        >
          <OurFeatures />
        </Box>
      </Box>
      <Paper
        sx={{
          padding: 3,
          bgcolor: "#e1f5fe",
          color: "#FFF",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ bgcolor: "#c8e6c9" }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <Typography
              variant="h3"
              sx={{
                margin: 2,
                color: "#00e676",
                fontWeight: "200",
              }}
            >
              Are you a healthcare service provider in Kenya?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                margin: 2,
                color: "coral",
                fontWeight: "200",
              }}
            >
              Offer A Space for Healing, Growth, and Discovery
            </Typography>
            <Button
              variant="contained"
              onClick={handleLoginOpen}
              onClose={handleLoginClose}
              sx={{
                backgroundColor: "#4caf50",
                marginBottom: "20px",
              }}
            >
              Sign Up Now
            </Button>
            <Modal
              open={openLoginModal}
              onClose={handleLoginClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <SignUp />
            </Modal>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-around",
            bgcolor: "#e1f5fe",
            color: "#4caf50",
            overflow: "hidden",
          }}
        >
          <Box
            flex={2}
            sx={{
              minWidth: 300,
              padding: theme.spacing(2),
              overflowY: "auto",
            }}
          >
            <Map resourceCenters={resourceCenters} userLocation={userLocation} />
          </Box>
          <Box
            flex={1}
            sx={{
              padding: theme.spacing(2),
              overflowY: "auto",
            }}
          >
            <QuickLinks />
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          paddingTop={3}
        >
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2} justifyContent="space-between">
              {cardData.map((card, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Card sx={{ backgroundColor: "#e0f2f1" }}>
                    <CardMedia
                      sx={{ height: 100 }}
                      image={card.image}
                      title={card.title}
                    />
                    <CardActionArea onClick={() => navigate(card.cardLink)}>
                      <CardContent>
                        <Typography
                          variant="h6"
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "#4caf50",
                          }}
                        >
                          {card.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            textAlign: "center",
                            marginTop: "8px",
                          }}
                        >
                          {card.content}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            display: "block",
                            textAlign: "center",
                            marginTop: "16px",
                          }}
                        >
                          {card.copyright}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        sx={{ color: "#4caf50" }}
                        size="small"
                        href={card.actionLink}
                      >
                        {card.action}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginTop: 2, textAlign: "center", color: "#3f51b5" }}
            >
              What Our Clients Say
            </Typography>
            <Slider {...settings} sx={{ margin: 2 }}>
              {testimonials.map((testimonial, index) => (
                <div key={index} style={{ padding: "20px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontStyle: "italic",
                      textAlign: "center",
                      color: "#7986cb",
                    }}
                  >
                    "{testimonial.quote}"
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      marginTop: "10px",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    - {testimonial.author}, {testimonial.position} at{" "}
                    {testimonial.company}
                  </Typography>
                </div>
              ))}
            </Slider>
            <Stack>
              <Typography
                color="#7986cb"
                sx={{ textAlign: "center", marginTop: 1 }}
              >
                Make an Appointment Today
              </Typography>
              <Button
                variant="contained"
                onClick={handleSocialOpen}
                onClose={handleSocialClose}
                sx={{
                  alignSelf: "center",
                  backgroundColor: "#3f51b5",
                  marginBottom: 2,
                }}
              >
                SIGN IN
              </Button>
              <Modal
                open={openSocialModal}
                onClose={handleSocialClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <SignIn handleCloseSignIn={handleSocialClose} />
              </Modal>
            </Stack>
          </Grid>
        </Grid>
        <IconMarquee />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" sx={{ color: "#7986cb" }}>
              All Rights Reserved
            </Typography>
            <Typography sx={{ color: "#7986cb" }}>
              © {new Date().getFullYear()} Macrobian Therapy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy-policy"
              >
                Privacy
              </Typography>
              <Typography
                sx={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/terms"
              >
                Terms of Use
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default HomePage;
