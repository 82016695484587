import React from "react";
import { Typography, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faTwitter,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import ResponsiveIcon from "./ResponsiveIcon";

const SocialMediaLinks = () => {
  return (
    <>
      <Typography
        color="#7986cb"
        sx={{
          fontSize: {
            xs: "12px", // Smaller on xs screens
            sm: "14px", // Adjust for sm screens
            md: "16px", // Target size for md screens and larger
            lg: "16px", // Ensuring it does not exceed 16px on lg screens
          },
          textAlign: "center", // Center the text on all screen sizes
          mb: 2, // Margin bottom for spacing
        }}
      >
        Follow Us
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          "& a": {
            marginX: {
              xs: "5px", // Smaller margin for small screens
              md: "10px", // Default margin for medium screens
              lg: "15px", // Larger margin for large screens
            },
          },
          flexDirection: {
            xs: "column", // Stack icons vertically on small screens
            md: "row", // Default horizontal layout for medium and large screens
          },
        }}
      >
        <a
          href="https://www.linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ResponsiveIcon icon={faLinkedin} style={{ color: "#0077b5" }} />
        </a>
        <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ResponsiveIcon icon={faTwitter} style={{ color: "#1DA1F2" }} />
        </a>
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ResponsiveIcon icon={faFacebookF} style={{ color: "#4267B2" }} />
        </a>
      </Box>
    </>
  );
};

export default SocialMediaLinks;
