import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Button } from '@mui/material';

const FlipAnimation = () => {
  const [flipped, setFlipped] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  return (
    <>
      <animated.div
        style={{
          opacity: opacity.interpolate(o => 1 - o),
          transform,
          textAlign: "center",
        }}
      >
        {/* Front Side of the Flip (e.g., the button) */}
        <Button
          variant="contained"
          style={{
            backgroundColor: "#4caf50",
          }}
          onClick={() => setFlipped(state => !state)}
        >
          Get Started
        </Button>
      </animated.div>
      <animated.div
        style={{
          opacity,
          transform: transform.interpolate(t => `${t} rotateX(180deg)`),
          position: 'absolute',
        }}
      >
        {/* Back Side of the Flip (e.g., content shown after flip) */}
        <div>Flipped Content Here</div>
      </animated.div>
    </>
  );
};
export default FlipAnimation;