import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  Box,
  Modal,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ForumIcon from "@mui/icons-material/Forum";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faTwitter, faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import logo from "../assets/images/logos/logo.jpg"; // Adjust the path to your logo file

const OurFeatures = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    contactPerson: '',
    phoneNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent(null);
    setFormData({ name: '', email: '', companyName: '', contactPerson: '', phoneNumber: '' });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleSubmit = async (endpoint, validatePhone = false) => {
    if (validatePhone && !validatePhoneNumber(formData.phoneNumber)) {
      setSnackbar({ open: true, message: "Please enter a valid phone number.", severity: 'error' });
      return;
    }
    setLoading(true);
    try {
      await axios.post(endpoint, formData);
      setSnackbar({ open: true, message: "Submission successful!", severity: 'success' });
      handleCloseModal();
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setSnackbar({ open: true, message: "Submission failed. Please try again.", severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const links = [
    {
      name: "Manage Your Appointments",
      icon: <HelpOutlineIcon style={{ color: "coral" }} />,
      description: "Teletherapy with assistive technology",
      title: "Manage Your Appointments Efficiently",
      endpoint: "https://therapy-api.accentrixcloud.com/appointments",
      content: (
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
          <img src={logo} alt="Logo" style={{ marginBottom: '20px', height: '50px' }} />
          <Typography variant="h6" gutterBottom>
            Simplify Your Appointment
          </Typography>
          <TextField required id="name" label="Name" name="name" onChange={handleChange} sx={{ mb: 2 }} />
          <TextField required id="email" label="Email" name="email" onChange={handleChange} type="email" sx={{ mb: 2 }} />
          <Button variant="contained" onClick={() => handleSubmit("https://therapy-api.accentrixcloud.com/appointments")} sx={{ backgroundColor: "coral" }}>
            {loading ? <CircularProgress size={24} color="inherit" /> : "Get Started"}
          </Button>
        </Box>
      ),
    },
    {
      name: "Cover Your Staff",
      icon: <SupportAgentIcon style={{ color: "coral" }} />,
      description: "Cover your staff for better productivity",
      title: "Protect and Empower Your Team",
      endpoint: "https://therapy-api.accentrixcloud.com/corporates",
      content: (
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
          <img src={logo} alt="Logo" style={{ marginBottom: '20px', height: '50px' }} />
          <Typography variant="h6" gutterBottom>
            Secure Your Workforce
          </Typography>
          <TextField required id="companyName" label="Company Name" name="companyName" onChange={handleChange} sx={{ mb: 2 }} />
          <TextField required id="companyEmail" label="Company Email" name="email" onChange={handleChange} type="email" sx={{ mb: 2 }} />
          <TextField required id="contactPerson" label="Contact Person" name="contactPerson" onChange={handleChange} sx={{ mb: 2 }} />
          <TextField required id="phoneNumber" label="Phone Number" name="phoneNumber" onChange={handleChange} type="tel" sx={{ mb: 2 }} />
          <Button variant="contained" onClick={() => handleSubmit("https://therapy-api.accentrixcloud.com/corporates", true)} sx={{ backgroundColor: "coral" }}>
            {loading ? <CircularProgress size={24} color="inherit" /> : "Get Started"}
          </Button>
        </Box>
      ),
    },
    {
      name: "Manage Your Clientelle",
      icon: <ForumIcon style={{ color: "coral" }} />,
      description: "Register your existing clientelle with ease",
      title: "Experience Therapy in a Better Way",
      endpoint: "https://therapy-api.accentrixcloud.com/service_providers",
      content: (
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
          <img src={logo} alt="Logo" style={{ marginBottom: '20px', height: '50px' }} />
          <Typography variant="h6" gutterBottom>
            Register Your Account
          </Typography>
          <TextField required id="name" label="Name" name="name" onChange={handleChange} sx={{ mb: 2 }} />
          <TextField required id="email" label="Email" name="email" onChange={handleChange} type="email" sx={{ mb: 2 }} />
          <Button variant="contained" onClick={() => handleSubmit("https://therapy-api.accentrixcloud.com/service_providers", true)} sx={{ backgroundColor: "coral" }}>
            {loading ? <CircularProgress size={24} color="inherit" /> : "Get Started"}
          </Button>
        </Box>
      ),
    },
    {
      name: "Share Your Experience",
      icon: <LocalHospitalIcon style={{ color: "coral" }} />,
      description: "Recommend a Therapist to friends and family",
      title: "Share Your Experience with Others",
      content: (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', p: 2 }}>
          <img src={logo} alt="Logo" style={{ marginBottom: '20px', height: '50px' }} />
          <Typography variant="h6" gutterBottom>
            Spread the Word
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <a 
              href="https://api.whatsapp.com/send?text=Check out therapy.co.ke for amazing therapy services!" 
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: '1rem' }} 
            >
              <FontAwesomeIcon icon={faWhatsapp} size="lg" style={{ backgroundColor: "#25D366", color: "white", padding: '10px', borderRadius: '50%' }} />
            </a>
            <a 
              href="https://twitter.com/intent/tweet?text=Check out therapy.co.ke for amazing therapy services!" 
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: '1rem' }}
            >
              <FontAwesomeIcon icon={faTwitter} size="lg" style={{ backgroundColor: "#1DA1F2", color: "white", padding: '10px', borderRadius: '50%' }} />
            </a>
            <a 
              href="https://www.facebook.com/sharer/sharer.php?u=https://therapy.co.ke" 
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: '1rem' }}
            >
              <FontAwesomeIcon icon={faFacebook} size="lg" style={{ backgroundColor: "#1877F2", color: "white", padding: '10px', borderRadius: '50%' }} />
            </a>
            <a 
              href="https://www.linkedin.com/sharing/share-offsite/?url=https://therapy.co.ke" 
              target="_blank"
              rel="noopener noreferrer" 
            >
              <FontAwesomeIcon icon={faLinkedin} size="lg" style={{ backgroundColor: "#0077B5", color: "white", padding: '10px', borderRadius: '50%' }} />
            </a>
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        borderLeft: 4,
        borderColor: "coral",
        pl: 2,
      }}
    >
      <Typography variant="h6" sx={{ color: "#3f51b5", alignSelf: "center" }}>
        Our Features
      </Typography>
      <List>
        {links.map((link, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={() => handleOpenModal(link.content)}>
              <ListItemIcon sx={{ color: "coral" }}>{link.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ color: "#3f51b5" }}>{link.name}</Typography>
                }
                secondary={
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {link.description}
                  </Typography>
                }
              />
            </ListItem>
            {index < links.length - 1 && <Divider sx={{ bgcolor: "coral" }} />}
          </React.Fragment>
        ))}
      </List>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 280,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            maxHeight: '90%',
            overflowY: 'auto',
          }}
        >
          {modalContent}
        </Box>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OurFeatures;