import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import IconMarquee from "../components/IconMarquee";
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import FlipAnimation from "../components/FlipAnimation";
import "../components/SliderStyles.css";

import SignIn from "./SignIn";

import { signInWithGoogle } from "../components/signInWithGoogle";

import SignupForm from "../components/SignupForm";

const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const testimonials = [
  {
    quote:
      "Using this platform has revolutionized the way our team approaches development. The intuitive design and robust features made it easy for us to integrate our projects seamlessly.",
    author: "Jamie Lannister",
    position: "Lead Developer",
    company: "Westeros Inc.",
  },
  {
    quote:
      "I was amazed by the level of support and community engagement I found here. It's not just about the tools; it's the people behind them that make the difference.",
    author: "Sansa Stark",
    position: "Product Manager",
    company: "North Enterprises",
  },
  {
    quote:
      "Transitioning to using these services has been a game changer for our productivity and workflow efficiency. Highly recommend to any teams looking to scale their development efforts.",
    author: "Tyrion Lannister",
    position: "CTO",
    company: "Lannister Solutions",
  },
  {
    quote:
      "As a freelance developer, finding a platform that I can rely on for all my projects has been a challenge. This service has met all my needs and exceeded my expectations in terms of usability and performance.",
    author: "Arya Stark",
    position: "Freelance Developer",
    company: "Independent",
  },
  {
    quote:
      "The attention to detail and the commitment to open-source principles have made this my go-to platform for development. It's rare to find such a powerful tool that's also community-driven.",
    author: "Daenerys Targaryen",
    position: "Software Engineer",
    company: "DragonTech",
  },
  // Add more testimonials as needed
];

const cardData = [
  {
    title: "Manage Appointments",
    content: "Manage bookings, cancellations, and rescheduling, in real-time",
    image:
      "https://images.unsplash.com/photo-1600091474842-83bb9c05a723?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    action: "Partner With Us",
    actionLink: "/partner",
  },
  {
    title: "Live Stream Therapy",
    content: "Secure, confidential, high-quality video conferencing facilities",
    image: "https://source.unsplash.com/random?mental-health",
    action: "Join Our Community",
    actionLink: "/community",
  },
  {
    title: "Track Progress",
    content: "Monitor clients' journey over time for tailored care.",
    image: "https://source.unsplash.com/random?general-health",
    action: "Referals",
    actionLink: "/referals",
  },
  // Add more card data as needed
];

const Corporates = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleSignInWithGoogle = async () => {
    try {
      // Sign in with Google and get the user data
      const user = await signInWithGoogle();
      console.log(user);

      // Send a GET request to your backend to fetch all users
      const backendResponse = await axios.get(
        "http://cvs.accentrixcloud.com/api/accounts"
      );
      const accounts = backendResponse.data;

      // Create a regex and find the matching account
      const regex = new RegExp(user.email);
      const matchingAccount = accounts.find((account) =>
        regex.test(account.email)
      );

      if (matchingAccount) {
        // Navigate based on user category obtained from the matching account
        switch (matchingAccount.is_admin) {
          case "1":
            navigate("/admin-profile", {
              state: { res_admin: matchingAccount },
            });
            break;
          case "0":
            navigate("/superuser-profile", {
              state: { res_superuser: matchingAccount },
            });
            break;
        }
      } else {
        alert("Email mismatch. Please try again.");
      }
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      alert("An error occurred while signing in. Please try again.");
    }
  };

  const handleSignInWithEmail = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    try {
      const response = await axios.get(
        "https://asset-management.accentrixcloud.com/api/accounts"
      );

      const accounts = response.data;

      // Create a regex and find the matching account
      const regex = new RegExp(email);
      const matchingAccount = accounts.find((account) =>
        regex.test(account.email)
      );

      // Navigate based on user category
      if (matchingAccount.is_admin) {
        navigate("/admin-profile", { state: { res_admin: matchingAccount } });
      } else {
        navigate("/user-profile", {
          state: { res_superuser: matchingAccount },
        });
      }
    } catch (error) {
      console.error("Sign in error:", error);
      alert(`An error occurred while signing in: ${error.message}`);
    }
  };

  const bannerStyle = {
    display: "flex",
    flexDirection: "column",
    marginTop: `${navbarHeight}px`,
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: 'url("https://source.unsplash.com/random?therapy")',
    position: "relative", // Needed for absolute positioning of the overlay
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)", // Adjust color and opacity here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "48px",
    position: "relative",
  };

  const subtitle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "28px",
    position: "relative",
  };

  return (
    <div>
      <Navbar />
      <div style={bannerStyle}>
        <div style={overlayStyle}></div>
        <Typography variant="h3" style={textStyle}>
          Corporate Section
        </Typography>
        <Typography variant="h5" style={subtitle}>
          Are You A Healthcare Service Provider? Join Us Today
        </Typography>
        <Button
          variant="contained"
          onClick={handleOpen}
          onClose={handleClose}
          sx={{
            backgroundColor: "#4caf50",
          }}
        >
          Get Started
        </Button>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SignIn handleSignInWithEmail={handleSignInWithEmail} />
        </Modal>
      </div>
      <Paper
        style={{
          padding: 20,
          backgroundColor: "#e1f5fe",
          color: "#FFF",
          minHeight: `calc(100vh - ${navbarHeight}px)`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2} justifyContent="space-between">
              {cardData.map((card, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Card sx={{ backgroundColor: "#b2dfdb" }}>
                    <CardMedia
                      sx={{ height: 100 }}
                      image={card.image}
                      title={card.title}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "#4caf50",
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "center",
                          marginTop: "8px",
                        }}
                      >
                        {card.content}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          display: "block",
                          textAlign: "center",
                          marginTop: "16px",
                        }}
                      >
                        {card.copyright}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <SignupForm />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#3f51b5" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#3f51b5" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy"
              >
                Privacy
              </Typography>
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy"
              >
                Terms of Use
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Corporates;
