import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
  Button,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import axios from "axios";

const SuperuserTherapySessions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [selectedSession, setSelectedSession] = useState(null);

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/sessions");
      setSessions(response.data);
      setFilteredSessions(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch sessions");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSessions(
      sessions.filter(
        (session) =>
          session.name.toLowerCase().includes(value) ||
          session.email.toLowerCase().includes(value) ||
          session.phoneNumber.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (session) => {
    setSelectedSession(session);
  };

  const handleCloseModal = () => {
    setSelectedSession(null);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Assessment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSessions.map((session) => (
            <TableRow key={session.id} onClick={() => handleRowClick(session)}>
              <TableCell>{new Date(session.date).toLocaleString()}</TableCell>
              <TableCell>{session.name}</TableCell>
              <TableCell>{session.email}</TableCell>
              <TableCell>{session.phoneNumber}</TableCell>
              <TableCell>{session.status}</TableCell>
              <TableCell>
                <Button variant="outlined" onClick={() => handleRowClick(session)}>
                  View Assessment
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Therapy Sessions</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search sessions"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredSessions.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No sessions found.
        </Typography>
      )}

      <Modal open={Boolean(selectedSession)} onClose={handleCloseModal} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {selectedSession && (
            <>
              <Typography variant="h6" gutterBottom>
                Session Details
              </Typography>
              <Typography variant="body1"><strong>Date:</strong> {new Date(selectedSession.date).toLocaleString()}</Typography>
              <Typography variant="body1"><strong>Name:</strong> {selectedSession.name}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {selectedSession.email}</Typography>
              <Typography variant="body1"><strong>Phone Number:</strong> {selectedSession.phoneNumber}</Typography>
              <Typography variant="body1"><strong>Status:</strong> {selectedSession.status}</Typography>
              <Typography variant="body1"><strong>Assessment:</strong> {selectedSession.assessment}</Typography>
              <Button onClick={handleCloseModal} color="primary" sx={{ mt: 2 }}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>

      <Modal open={Boolean(error)} onClose={() => setError("")}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
          <Button onClick={() => setError("")} color="primary">
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserTherapySessions;
