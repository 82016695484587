import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import Navbar from "./Navbar";
import logo from "../assets/images/logos/logo.jpg";
import SocialMediaLinks from "./SocialMediaLinks";
function ContactUs() {
  const navbarHeight = 64;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission, e.g., send data to a server
    console.log(formData);
  };

  const bannerStyle = {
    display: "flex",
    flexDirection: "column",
    marginTop: `${navbarHeight}px`,
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: 'url("https://source.unsplash.com/random?therapy")',
    position: "relative", // Needed for absolute positioning of the overlay
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)", // Adjust color and opacity here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "48px",
    position: "relative",
  };

  const subtitle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "28px",
    position: "relative",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        backgroundColor: "#e1f5fe",
      }}
    >
      <Navbar />
      <div style={bannerStyle}>
        <div style={overlayStyle}></div>
        <Typography variant="h3" style={textStyle}>
          Contact Us
        </Typography>
        <Typography variant="h5" style={subtitle}>
          Have anything for us? Reach Out
        </Typography>
      </div>
      <Container>
        <Grid container spacing={4} pt={4}>
          {/* Company information and images */}
          <Grid item xs={12} md={6} textAlign="center">
            <Typography variant="h4" gutterBottom color="#3f51b5">
              About Our Company
            </Typography>
            <Typography variant="body1" paragraph>
              We provide access to the best therapy services in Kenya.
            </Typography>
            <Box
              component="img"
              sx={{
                height: "auto",
                width: "50%",
                borderRadius: "4px",
              }}
              alt="Company image"
              src={logo} // Replace with the path to your image
            />
          </Grid>

          {/* Contact form */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4, bgcolor: "#e0f2f1" }}>
              <Typography variant="h5" gutterBottom color="#4caf50">
                Contact Us
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  variant="outlined"
                  margin="normal"
                  value={formData.name}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  variant="outlined"
                  margin="normal"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, bgcolor: "#4caf50" }}
                >
                  Send
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} sm={4}>
          <Typography variant="caption" style={{ color: "#7986cb" }}>
            All Rights Reserved
          </Typography>
          <Typography style={{ color: "#7986cb" }}>
            © {new Date().getFullYear()} Macrobian Health
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <div
            style={{
              flexGrow: 0.1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{ color: "#3f51b5", textDecoration: "none" }}
              component={Link}
              to="/privacy"
            >
              Privacy
            </Typography>
            <Typography
              style={{ color: "#3f51b5", textDecoration: "none" }}
              component={Link}
              to="/privacy"
            >
              Terms of Use
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={4}>
          <SocialMediaLinks />
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUs;
