import React from 'react';
import { TreeView } from "@mui/x-tree-view";
import { TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';

const CorporateMenu = ({ handleProfileViewClick, handleQuestionaireViewClick, handleBillingViewClick, handleDashboardViewClick, handleStaffViewClick, handlePostRecoveryViewClick, handleSubscriptionsViewClick}) => {
  return (
    <TreeView
    aria-label="file system navigator"
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpandIcon={<ChevronRightIcon />}
  >
    <TreeItem nodeId="1" label="Your Inbox">
      <TreeItem nodeId="2" onClick={handleDashboardViewClick} label="View" />
    </TreeItem>
    <TreeItem nodeId="3" label="Your Staff">
      <TreeItem nodeId="4" onClick={handleStaffViewClick} label="View" />
    </TreeItem>
      <TreeItem nodeId="11" label="Therapy">
        <TreeItem nodeId="12" onClick={handleQuestionaireViewClick} label="Questions" />
        <TreeItem nodeId="21" onClick={handlePostRecoveryViewClick} label="Post Recovery" />
      </TreeItem>
      <TreeItem nodeId="5" label="Subscriptions">
      <TreeItem nodeId="10" onClick={handleSubscriptionsViewClick} label="View" />
    </TreeItem>
    <TreeItem nodeId="13" label="Billing">
      <TreeItem nodeId="14" onClick={handleBillingViewClick} label="View" />
    </TreeItem>
    <TreeItem nodeId="26" label="Your Profile">
      <TreeItem nodeId="27" onClick={handleProfileViewClick} label="Settings" />
    </TreeItem>
    <TreeItem nodeId="15" label="Exit">
      <TreeItem nodeId="16" label={ <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}> Sign Out </Link> } />
    </TreeItem>
  </TreeView>
  );
};

export default CorporateMenu;
