import React from 'react';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const StyledResponsiveIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  // Default size for xs screens
  fontSize: '12px', // Smaller on xs screens
  // Adjust sizes for other breakpoints
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px', // Slightly larger on sm screens
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px', // Target size for md screens and larger
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '16px', // Ensuring it does not exceed 16px on lg screens
  },
}));


const ResponsiveIcon = (props) => {
  // Spread props to the StyledResponsiveIcon for flexibility
  return <StyledResponsiveIcon {...props} />;
};

// Prop validation (optional but recommended for better developer experience)
ResponsiveIcon.propTypes = {
  icon: PropTypes.object.isRequired, // Ensure icon prop is passed and is an object
};

export default ResponsiveIcon;
