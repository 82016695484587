import { firebaseApp, googleProvider } from "../firebaseConfig"
import { getAuth, signInWithPopup } from 'firebase/auth';
const auth = getAuth(firebaseApp);

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    // You can extract user information from result
    const user = result.user;
    return user;
  } catch (error) {
    console.error('Error during Google Sign-In', error);
    throw error;
  }
};