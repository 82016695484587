// firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCP-eSkk0KC2cC8BWvjtBFZY66DztBTvRo",
  authDomain: "main-335710.firebaseapp.com",
  projectId: "main-335710",
  storageBucket: "main-335710.appspot.com",
  messagingSenderId: "729516601836",
  appId: "1:729516601836:web:fbf430e3027696095ed0c9",
  measurementId: "G-Y4YT17S97N"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider(); // Adding the FacebookAuthProvider

export { firebaseApp, googleProvider, facebookProvider };
