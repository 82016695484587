import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
  Button,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import axios from "axios";

const SuperuserBlogPosts = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [blogPosts, setBlogPosts] = useState([]);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [selectedBlogPost, setSelectedBlogPost] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/blogposts");
      setBlogPosts(response.data);
      setFilteredBlogPosts(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch blog posts data");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBlogPosts(
      blogPosts.filter(
        (post) =>
          post.title.toLowerCase().includes(value) ||
          post.author.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (post) => {
    setSelectedBlogPost(post);
    setFormData(post);
    setOpenEditModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`https://therapy-api.accentrixcloud.com/blogposts/${selectedBlogPost.id}`, formData);
      fetchBlogPosts();
      setOpenEditModal(false);
    } catch (error) {
      setError("Failed to update blog post data");
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`https://therapy-api.accentrixcloud.com/blogposts/${selectedBlogPost.id}`);
      fetchBlogPosts();
      setOpenDeleteModal(false);
    } catch (error) {
      setError("Failed to delete blog post data");
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBlogPosts.map((post) => (
            <TableRow key={post.id} onClick={() => handleRowClick(post)}>
              <TableCell>{post.title}</TableCell>
              <TableCell>{post.author}</TableCell>
              <TableCell>{new Date(post.date).toLocaleString()}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleRowClick(post)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => {
                  setSelectedBlogPost(post);
                  setOpenDeleteModal(true);
                }}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Blog Posts</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search blog posts"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredBlogPosts.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No blog posts found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>Edit Blog Post</Typography>
          <Box component="form" onSubmit={handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              value={formData.title || ""}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="author"
              label="Author"
              name="author"
              value={formData.author || ""}
              onChange={(e) => setFormData({ ...formData, author: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="content"
              label="Content"
              name="content"
              multiline
              rows={4}
              value={formData.content || ""}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this blog post?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
          >
            Delete
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal open={Boolean(error)} onClose={() => setError("")}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
          <Button onClick={() => setError("")} color="primary">
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserBlogPosts;