import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
  IconButton,
  Badge
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTasks, faDollarSign, faUsers, faComments, faUserMd, faCartPlus, faBlog, faMapMarkerAlt, faEnvelopeOpenText, faCalendarCheck, faFileAlt, faUserCircle, faArrowLeft, faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ServiceProviderAppointments from "../components/ServiceProviderAppointments";
import ServiceProviderPostRecovery from "../components/ServiceProviderPostRecovery";
import ServiceProviderQuestionnaire from "../components/ServiceProviderQuestionnaire";
import ServiceProviderSettings from "../components/ServiceProviderSettings";
import ServiceProviderSubscriptions from "../components/ServiceProviderSubscriptions";
import ServiceProviderBills from "../components/ServiceProviderBills";
import ServiceProviderClients from "../components/ServiceProviderClients";
import ServiceProviderStaff from "./ServiceProviderStaff";
import ServiceProviderTherapyAssessments from "./ServiceProviderTherapyAssessments";
import ServiceProviderTherapySessions from "./ServiceProviderTherapySessions";
import ServiceProviderTherapyTreatments from "./ServiceProviderTherapyTreatments";

const ServiceProviderDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedComponent, setSelectedComponent] = useState(null);

  const icons = [
    { name: "Appointments", icon: faCalendarAlt, component: 'Appointments', badgeCount: 5 },
    { name: "Responses", icon: faComments, component: 'Responses', badgeCount: 3 },
    { name: "Billing", icon: faDollarSign, component: 'Billing', badgeCount: 7 },
    { name: "Sessions", icon: faUserMd, component: 'Sessions', badgeCount: 2 },
    { name: "Treatments", icon: faTasks, component: 'Treatments', badgeCount: 4 },
    { name: "Post Recovery", icon: faCalendarCheck, component: 'PostRecovery', badgeCount: 6 },
    { name: "Assessments", icon: faFileAlt, component: 'Assessments', badgeCount: 3 },
    { name: "Tickets", icon: faEnvelopeOpenText, component: 'Tickets', badgeCount: 4 },
    { name: "Our Shop", icon: faCartPlus, component: 'OurShop', badgeCount: 6 },
    { name: "Inventory", icon: faTasks, component: 'Inventory', badgeCount: 1 },
    { name: "Calendar", icon: faCalendarAlt, component: 'Calendar', badgeCount: 2 },
    { name: "Clients", icon: faUsers, component: 'Clients', badgeCount: 7 },
    { name: "Teams", icon: faUsers, component: 'Teams', badgeCount: 1 },
    { name: "Settings", icon: faCog, component: 'Settings', badgeCount: 0 },
    { name: "Logout", icon: faSignOutAlt, component: 'Logout', badgeCount: 0 },
  ];

  useEffect(() => {
    setLoading(false);  // Simulating loading finished
  }, []);

  const handleLogout = () => {
    axios.post('/logout')
      .then(() => {
        navigate('/login');
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Logout failed. Please try again.', severity: 'error' });
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const renderIcons = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: 'center' }}>
          <Badge badgeContent={icon.badgeCount} color="secondary">
            <IconButton onClick={() => {
              if (icon.component === 'Logout') {
                handleLogout();
              } else {
                setSelectedComponent(icon.component);
              }
            }} sx={{ fontSize: 40, color: 'primary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)', color: 'secondary.main' } }}>
              <FontAwesomeIcon icon={icon.icon} />
            </IconButton>
          </Badge>
          <Typography variant="body1">{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Appointments':
        return <ServiceProviderAppointments />;
      case 'Responses':
        return <ServiceProviderTherapyAssessments />;
      case 'Billing':
        return <ServiceProviderBills />;
      case 'Sessions':
        return <ServiceProviderTherapySessions />;
      case 'Treatments':
        return <ServiceProviderTherapyTreatments />;
      case 'PostRecovery':
        return <ServiceProviderPostRecovery />;
      
      case 'OurShop':
        return <ServiceProviderClients />;
      case 'Inventory':
        return <ServiceProviderClients />;
      
      case 'Clients':
        return <ServiceProviderClients />;
      case 'Teams':
        return <ServiceProviderStaff />;
      case 'Settings':
        return <ServiceProviderSettings />;
      default:
        return renderIcons();
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {selectedComponent && (
          <Link underline="hover" color="inherit" onClick={() => setSelectedComponent(null)} style={{ cursor: 'pointer' }}>
            Home
          </Link>
        )}
        {selectedComponent && (
          <Typography color="text.primary">{selectedComponent}</Typography>
        )}
        {!selectedComponent && (
          <Typography color="text.primary">Home</Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        renderComponent()
      )}
      {selectedComponent && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <IconButton onClick={() => setSelectedComponent(null)} sx={{ fontSize: 40, color: 'primary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)', color: 'secondary.main' } }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ServiceProviderDashboard;
