import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Modal,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import Navbar from "./Navbar";
import "./SliderStyles.css";

import BillingTable from "./BillingTable"
import UserMenu from "./UserMenu";
import TicketModal from "./TicketModal";


const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];



const ViewBilling = () => {


  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);


  const handleBillingViewClick = () => {
    navigate('/view-billing');
};

const handleDashboardViewClick = () => {
    navigate('/user-profile');
};

const handleQuestionaireViewClick = () => {
    navigate('/view-questionaire');
};

const handleAppointmentsViewClick = () => {
  navigate('/view-appointments');
};

const handleAssessmentsViewClick = () => {
  navigate('/view-assessments');
};

const handleSessionsViewClick = () => {
  navigate('/view-sessions');
};

const handleTreatmentsViewClick = () => {
  navigate('/view-treatments');
};

const handlePostRecoveryViewClick = () => {
  navigate('/view-post-recovery');
};

const handleSubscriptionsViewClick = () => {
  navigate('/view-subscriptions');
};

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Navbar />
      <Paper
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          marginTop: `${navbarHeight}px`,
          padding: 20,
          backgroundColor: "#1B277D",
          color: "#FFF",
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex", height: "calc(100vh - 64px)" }}>
          <Box
            sx={{
              width: { xs: 0, sm: "200px" }, // Adjust width as needed
              overflowY: "auto",
            }}
          >
            {/* Dashboard and TreeView go here */}
            <Typography variant="h6" onClick={handleDashboardViewClick} style={{ padding: "16px" }}>
              Dashboard
            </Typography>
            {/* TreeView component */}
          <UserMenu handleBillingViewClick={handleBillingViewClick} handleQuestionaireViewClick={handleQuestionaireViewClick} handleDashboardViewClick={handleDashboardViewClick} handleAppointmentsViewClick={handleAppointmentsViewClick} handleAssessmentsViewClick={handleAssessmentsViewClick} handleSessionsViewClick={handleSessionsViewClick} handleTreatmentsViewClick={handleTreatmentsViewClick} handlePostRecoveryViewClick={handlePostRecoveryViewClick} handleSubscriptionsViewClick={handleSubscriptionsViewClick} />
          </Box>
          <Box sx={{ flex: 1, overflowY: "auto" }}>
            {/* Main content goes here */}
            <Paper
              elevation={3}
              style={{
                margin: "16px",
                padding: "16px",
                flexGrow: 1,
                overflow: "auto",
                height: "calc(100vh - 180px - 32px)",
                backgroundColor: "#b2dfdb",
              }}
            >
              <BillingTable />
            </Paper>
          </Box>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#00e5ff" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#fff" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography color="#00e5ff" style={{ textAlign: "center" }}>
              Do you need any support?
            </Typography>
            <Button
              variant="contained"
              style={{
                margin: "auto",
                display: "block",
                backgroundColor: "#4caf50",
              }}
              onClick={handleOpen}
            >
              Talk To Us
            </Button>
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <TicketModal open={openModal} onClose={handleClose} />
            </Modal>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
              <SpeedDial
                ariaLabel="SpeedDial"
                sx={{
                  "& .MuiFab-primary": {
                    backgroundColor: "#4caf50",
                    "&:hover": { backgroundColor: "#43a047" },
                  },
                }}
                icon={<SpeedDialIcon />}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                  />
                ))}
              </SpeedDial>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ViewBilling;
