import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  useTheme,
  useMediaQuery,
  Modal,
  TextField,
  Alert
} from "@mui/material";
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";
import axios from 'axios';

const navbarHeight = 64;

const heroStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "500px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: `url("https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fGhlYWx0aGNhcmV8ZW58MHx8MHx8fDA%3D")`,
  position: "relative",
  color: "white",
  textAlign: "center",
  padding: "0 20px",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
};

const ServiceProvider = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [signInError, setSignInError] = useState('');
  const [signUpError, setSignUpError] = useState('');
  const [signInForm, setSignInForm] = useState({ email: '', password: '' });
  const [signUpForm, setSignUpForm] = useState({ companyName: '', email: '', contactPerson: '', phone: '', location: '', specialization: '' });

  const handleSignInChange = (e) => {
    const { name, value } = e.target;
    setSignInForm({ ...signInForm, [name]: value });
  };

  const handleSignUpChange = (e) => {
    const { name, value } = e.target;
    setSignUpForm({ ...signUpForm, [name]: value });
  };

  const handleSignInSubmit = () => {
    axios.post('/signin', signInForm)
      .then(response => {
        // Handle successful sign in
        setSignInModalOpen(false);
        navigate('/dashboard');
      })
      .catch(error => {
        setSignInError('Failed to sign in. Please check your credentials.');
      });
  };

  const handleSignUpSubmit = () => {
    axios.post('/service-providers', signUpForm)
      .then(response => {
        // Handle successful sign up
        setSignUpModalOpen(false);
        navigate('/welcome');
      })
      .catch(error => {
        setSignUpError('Failed to sign up. Please check the details and try again.');
      });
  };

  const features = [
    {
      title: "Comprehensive Teletherapy",
      description: "Offer your services through secure and reliable teletherapy sessions.",
      image: "https://plus.unsplash.com/premium_photo-1682141160157-d6a296a65574?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aGVhbHRoY2FyZXxlbnwwfHwwfHx8MA%3D%3D",
    },
    {
      title: "Secure Patient Records",
      description: "Manage patient records with advanced security measures.",
      image: "https://images.unsplash.com/photo-1603899122634-f086ca5f5ddd?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2VjdXJlfGVufDB8fDB8fHww",
    },
    {
      title: "Efficient Billing",
      description: "Streamline billing processes for both corporate and individual clients.",
      image: "https://images.unsplash.com/photo-1556742502-ec7c0e9f34b1?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHBheW1lbnRzfGVufDB8fDB8fHww",
    },
  ];

  const testimonials = [
    {
      name: "Dr. John Doe",
      feedback: "This platform has transformed the way we deliver care. Highly recommend!",
      image: "https://plus.unsplash.com/premium_photo-1661551872938-f7d4ca7b7d19?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8bGFkeSUyMGJsYWNrJTIwZG9jdG9yfGVufDB8fDB8fHww",
    },
    {
      name: "Jane Smith, LCSW",
      feedback: "Efficient, secure, and user-friendly. It's a game changer for therapists.",
      image: "https://plus.unsplash.com/premium_photo-1682130171029-49261a5ba80a?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYmxhY2slMjBkb2N0b3J8ZW58MHx8MHx8fDA%3D",
    },
  ];

  return (
    <div>
      <Navbar />
      <Box style={heroStyle}>
        <Box style={overlayStyle}></Box>
        <Typography variant="h3" sx={{ zIndex: 2, fontSize: isMobile ? "36px" : "48px" }}>
          Join Our Healthcare Network
        </Typography>
        <Typography variant="h5" sx={{ zIndex: 2, marginTop: 2 }}>
          Enhance your practice with our comprehensive teletherapy platform
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ zIndex: 2, marginTop: 4 }}
          onClick={() => setSignInModalOpen(true)}
        >
          Get Started
        </Button>
      </Box>

      <Box sx={{ padding: theme.spacing(4), backgroundColor: "#f5f5f5" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Key Features
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card>
                <CardMedia component="img" height="140" image={feature.image} alt={feature.title} />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ padding: theme.spacing(4), backgroundColor: "#ffffff" }}>
        <Typography variant="h4" align="center" gutterBottom>
          What Our Providers Say
        </Typography>
        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="240"
                  image={testimonial.image}
                  alt={testimonial.name}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {testimonial.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {testimonial.feedback}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ padding: theme.spacing(4), textAlign: "center", backgroundColor: "#3f51b5", color: "#ffffff" }}>
        <Typography variant="h4" gutterBottom>
          Ready to Transform Your Practice?
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => setSignUpModalOpen(true)}
        >
          Join Now
        </Button>
      </Box>

      <Paper
        style={{
          padding: 20,
          backgroundColor: "#e1f5fe",
          color: "#FFF",
        }}
      >
        <Grid container spacing={2} mt={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#7986cb" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#7986cb" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy"
              >
                Privacy
              </Typography>
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/terms"
              >
                Terms of Use
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>

      {/* Sign-In Modal */}
      <Modal
        open={signInModalOpen}
        onClose={() => setSignInModalOpen(false)}
        aria-labelledby="sign-in-modal-title"
        aria-describedby="sign-in-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="sign-in-modal-title" variant="h6" component="h2">
            Sign In
          </Typography>
          <TextField
            label="Email"
            name="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={signInForm.email}
            onChange={handleSignInChange}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={signInForm.password}
            onChange={handleSignInChange}
          />
          {signInError && <Alert severity="error">{signInError}</Alert>}
          <Button variant="contained" color="primary" fullWidth onClick={handleSignInSubmit}>
            Sign In
          </Button>
        </Box>
      </Modal>

      {/* Sign-Up Modal */}
      <Modal
        open={signUpModalOpen}
        onClose={() => setSignUpModalOpen(false)}
        aria-labelledby="sign-up-modal-title"
        aria-describedby="sign-up-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="sign-up-modal-title" variant="h6" component="h2">
            Join Us
          </Typography>
          <TextField
            label="Company Name"
            name="companyName"
            fullWidth
            margin="normal"
            variant="outlined"
            value={signUpForm.companyName}
            onChange={handleSignUpChange}
          />
          <TextField
            label="Email"
            name="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={signUpForm.email}
            onChange={handleSignUpChange}
          />
          <TextField
            label="Contact Person"
            name="contactPerson"
            fullWidth
            margin="normal"
            variant="outlined"
            value={signUpForm.contactPerson}
            onChange={handleSignUpChange}
          />
          <TextField
            label="Phone"
            name="phone"
            fullWidth
            margin="normal"
            variant="outlined"
            value={signUpForm.phone}
            onChange={handleSignUpChange}
          />
          <TextField
            label="Location"
            name="location"
            fullWidth
            margin="normal"
            variant="outlined"
            value={signUpForm.location}
            onChange={handleSignUpChange}
          />
          <TextField
            label="Specialization"
            name="specialization"
            fullWidth
            margin="normal"
            variant="outlined"
            value={signUpForm.specialization}
            onChange={handleSignUpChange}
          />
          {signUpError && <Alert severity="error">{signUpError}</Alert>}
          <Button variant="contained" color="primary" fullWidth onClick={handleSignUpSubmit}>
            Join
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ServiceProvider;
