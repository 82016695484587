import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import axios from "axios";

const CorporateStaff = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/corporate-staff");
      setStaff(response.data);
      setFilteredStaff(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch staff", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredStaff(
      staff.filter(staff =>
        staff.name.toLowerCase().includes(value) ||
        staff.email.toLowerCase().includes(value) ||
        staff.phone.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenAddEditModal = (staff = null) => {
    setSelectedStaff(staff);
    setFormData(staff || {});
    setOpenAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setOpenAddEditModal(false);
    setFormData({});
  };

  const handleOpenDeleteModal = (staff) => {
    setSelectedStaff(staff);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedStaff(null);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleAddEditSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (selectedStaff) {
        await axios.put(`https://therapy-api.accentrixcloud.com/corporate-staff/${selectedStaff.id}`, formData);
      } else {
        await axios.post("https://therapy-api.accentrixcloud.com/corporate-staff", formData);
      }
      fetchStaff();
      handleCloseAddEditModal();
    } catch (error) {
      console.error("Failed to save staff", error);
      setLoading(false);
    }
  };

  const handleDeleteSubmit = async () => {
    setLoading(true);
    try {
      await axios.delete(`https://therapy-api.accentrixcloud.com/corporate-staff/${selectedStaff.id}`);
      fetchStaff();
      handleCloseDeleteModal();
    } catch (error) {
      console.error("Failed to delete staff", error);
      setLoading(false);
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Department</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredStaff.length > 0 ? (
            filteredStaff.map((staff) => (
              <TableRow key={staff.id} onClick={() => handleOpenAddEditModal(staff)}>
                <TableCell>{staff.name}</TableCell>
                <TableCell>{staff.email}</TableCell>
                <TableCell>{staff.phone}</TableCell>
                <TableCell>{staff.department}</TableCell>
                <TableCell>{staff.gender}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleOpenAddEditModal(staff); }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleOpenDeleteModal(staff); }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No corporate staff records available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleCloseAddEditModal}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Staff Details</Typography>
      <Box component="form" onSubmit={handleAddEditSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={formData.name || ""}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={formData.email || ""}
          onChange={handleChange}
          type="email"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Phone"
          name="phone"
          value={formData.phone || ""}
          onChange={handleChange}
          type="tel"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="department"
          label="Department"
          name="department"
          value={formData.department || ""}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="gender"
          label="Gender"
          name="gender"
          value={formData.gender || ""}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Corporate Staff</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search staff"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ ml: 2 }}
          onClick={() => handleOpenAddEditModal()}
        >
          Add Staff
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedStaff ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openAddEditModal} onClose={handleCloseAddEditModal}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {renderDetails()}
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            Confirm Delete
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this staff record?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDeleteSubmit}
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default CorporateStaff;
