import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, CssBaseline, Paper, Avatar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from "../assets/images/logos/logo.jpg";


const theme = createTheme();

function SetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const email = query.get('email');

    useEffect(() => {
        if (password || confirmPassword) {
            setError('');
        }
    }, [password, confirmPassword]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const response = await axios.put('https://therapy-api.accentrixcloud.com/set-password', {
                email,
                password
            });
            if (response.status === 200) {
                setSuccess(true);
                setTimeout(() => navigate('/'), 3000);
            }
        } catch (err) {
            setError('Failed to set password');
            console.error(err);
        }
    };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{ mt: 8, mb: 4 }}>
        <CssBaseline />
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 3,
            bgcolor: "#e0e0e0",
            borderRadius: 3,
            color: "#ffffff",
          }}
        >
          <Avatar
            src={logo}
            sx={{ mt: 2, bgcolor: "common.white", width: 86, height: 86 }}
          />
          <Typography
            component="h1"
            variant="h5"
            sx={{ mb: 2, color: "#3f51b5" }}
          >
            Set Your Password
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          {success ? (
            <Typography>
              Password set successfully! Redirecting to login...
            </Typography>
          ) : (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "primary.main",
                  "&:hover": { bgcolor: "primary.dark" },
                }}
              >
                Set Password
              </Button>
            </Box>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default SetPassword;
