import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
  Button,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import axios from "axios";

const SuperuserResourceCenters = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [resourceCenters, setResourceCenters] = useState([]);
  const [filteredResourceCenters, setFilteredResourceCenters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [selectedResourceCenter, setSelectedResourceCenter] = useState(null);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    contact: "",
    specialization: "",
    lat: "",
    lng: ""
  });

  useEffect(() => {
    fetchResourceCenters();
  }, []);

  const fetchResourceCenters = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/api/resource-centres");
      setResourceCenters(response.data);
      setFilteredResourceCenters(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch resource centers data");
      setLoading(false);
    }
  };

  const fetchResources = async (centerId) => {
    try {
      const response = await axios.get(`https://therapy-api.accentrixcloud.com/api/resource-centres/${centerId}/resources`);
      setResources(response.data);
      setFilteredResources(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch resources data");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (selectedResourceCenter) {
      setFilteredResources(
        resources.filter(
          (resource) =>
            resource.name.toLowerCase().includes(value) ||
            resource.specialization.toLowerCase().includes(value)
        )
      );
    } else {
      setFilteredResourceCenters(
        resourceCenters.filter(
          (center) =>
            center.center.toLowerCase().includes(value) ||
            center.location.toLowerCase().includes(value)
        )
      );
    }
  };

  const handleRowClick = (center) => {
    setSelectedResourceCenter(center);
    fetchResources(center.id);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`https://therapy-api.accentrixcloud.com/api/resource-centres/${selectedResourceCenter.id}`, formData);
      fetchResourceCenters();
      setOpenEditModal(false);
    } catch (error) {
      setError("Failed to update resource center data");
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`https://therapy-api.accentrixcloud.com/api/resource-centres/${selectedResourceCenter.id}`);
      fetchResourceCenters();
      setOpenDeleteModal(false);
    } catch (error) {
      setError("Failed to delete resource center data");
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post("https://therapy-api.accentrixcloud.com/api/resource-centres", formData);
      fetchResourceCenters();
      setOpenAddModal(false);
    } catch (error) {
      setError("Failed to add resource center");
    }
  };

  const renderResourcesTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Specialization</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredResources.map((resource) => (
            <TableRow key={resource.id}>
              <TableCell>{resource.name}</TableCell>
              <TableCell>{resource.specialization}</TableCell>
              <TableCell>{resource.contact}</TableCell>
              <TableCell>
                <IconButton>
                  <EditIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell>Specialization</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredResourceCenters.map((center) => (
            <TableRow key={center.id} onClick={() => handleRowClick(center)}>
              <TableCell>{center.center}</TableCell>
              <TableCell>{center.location}</TableCell>
              <TableCell>{center.tel}</TableCell>
              <TableCell>{center.specialization}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleRowClick(center)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => {
                  setSelectedResourceCenter(center);
                  setOpenDeleteModal(true);
                }}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        {selectedResourceCenter ? `Resources at ${selectedResourceCenter.center}` : "Therapy Resource Centers"}
      </Typography>
      <Toolbar>
        <IconButton onClick={() => setSelectedResourceCenter(null)} disabled={!selectedResourceCenter}>
          <ArrowBackIcon />
        </IconButton>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenAddModal(true)}
          sx={{ ml: 2 }}
        >
          {selectedResourceCenter ? "Add Resource" : "Add Resource Center"}
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedResourceCenter ? (
        filteredResources.length > 0 ? (
          renderResourcesTable()
        ) : (
          <Typography variant="body1" sx={{ mt: 4 }}>
            No resources found.
          </Typography>
        )
      ) : filteredResourceCenters.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No resource centers found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>Edit Resource Center</Typography>
          <Box component="form" onSubmit={handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.center || ""}
              onChange={(e) => setFormData({ ...formData, center: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="location"
              label="Location"
              name="location"
              value={formData.location || ""}
              onChange={(e) => setFormData({ ...formData, location: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="contact"
              label="Contact"
              name="contact"
              value={formData.tel || ""}
              onChange={(e) => setFormData({ ...formData, tel: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="specialization"
              label="Specialization"
              name="specialization"
              value={formData.specialization || ""}
              onChange={(e) => setFormData({ ...formData, specialization: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lat"
              label="Latitude"
              name="lat"
              value={formData.lat || ""}
              onChange={(e) => setFormData({ ...formData, lat: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lng"
              label="Longitude"
              name="lng"
              value={formData.lng || ""}
              onChange={(e) => setFormData({ ...formData, lng: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>Add {selectedResourceCenter ? "Resource" : "Resource Center"}</Typography>
          <Box component="form" onSubmit={selectedResourceCenter ? handleAddSubmit : handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.center || ""}
              onChange={(e) => setFormData({ ...formData, center: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="location"
              label="Location"
              name="location"
              value={formData.location || ""}
              onChange={(e) => setFormData({ ...formData, location: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="contact"
              label="Contact"
              name="contact"
              value={formData.tel || ""}
              onChange={(e) => setFormData({ ...formData, tel: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="specialization"
              label="Specialization"
              name="specialization"
              value={formData.specialization || ""}
              onChange={(e) => setFormData({ ...formData, specialization: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lat"
              label="Latitude"
              name="lat"
              value={formData.lat || ""}
              onChange={(e) => setFormData({ ...formData, lat: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lng"
              label="Longitude"
              name="lng"
              value={formData.lng || ""}
              onChange={(e) => setFormData({ ...formData, lng: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this resource center?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
          >
            Delete
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal open={Boolean(error)} onClose={() => setError("")}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
          <Button onClick={() => setError("")} color="primary">
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserResourceCenters;
