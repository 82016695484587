import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, Payment as PaymentIcon } from "@mui/icons-material";
import axios from 'axios';

const BillingTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [selectedBill, setSelectedBill] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [currentPayment, setCurrentPayment] = useState({ amount: 0, item: '' });
  const phoneNumber = '254716502769';

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      // Replace the URL with your actual endpoint
      const response = await axios.get("https://therapy-api.accentrixcloud.com/billing");
      setBills(response.data);
      setFilteredBills(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch bills", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBills(
      bills.filter(bill =>
        bill.subscription.toLowerCase().includes(value) ||
        bill.status.toLowerCase().includes(value) ||
        bill.period.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (bill) => {
    setSelectedBill(bill);
  };

  const handleBackClick = () => {
    setSelectedBill(null);
  };

  const openPaymentConfirmation = (bill) => {
    setCurrentPayment({ amount: bill.amount, item: bill.subscription });
    setOpenConfirmDialog(true);
  };

  const closeDialogs = () => {
    setOpenConfirmDialog(false);
    setOpenAlertDialog(false);
  };

  const confirmPayment = async () => {
    closeDialogs();
    try {
      const response = await axios.post('https://theyard.accentrixcloud.com/stk_push', {
        phone_number: phoneNumber,
        amount: currentPayment.amount,
        item: currentPayment.item
      });
      setAlertMessage(`Payment initiated for ${currentPayment.item} - Ksh ${currentPayment.amount.toFixed(2)}`);
      setOpenAlertDialog(true);
    } catch (error) {
      setAlertMessage(`Error in payment process: ${error.message || error}`);
      setOpenAlertDialog(true);
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Subscription</TableCell>
            <TableCell>Period</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBills.length > 0 ? (
            filteredBills.map((bill) => (
              <TableRow key={bill.id} onClick={() => handleRowClick(bill)}>
                <TableCell>{new Date(bill.date).toLocaleDateString()}</TableCell>
                <TableCell>{bill.subscription}</TableCell>
                <TableCell>{bill.period}</TableCell>
                <TableCell>{bill.status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PaymentIcon />}
                    onClick={() => openPaymentConfirmation(bill)}
                  >
                    Pay
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No subscription records available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Subscription Details</Typography>
      <Typography variant="body1"><strong>Date:</strong> {new Date(selectedBill.date).toLocaleDateString()}</Typography>
      <Typography variant="body1"><strong>Subscription:</strong> {selectedBill.subscription}</Typography>
      <Typography variant="body1"><strong>Period:</strong> {selectedBill.period}</Typography>
      <Typography variant="body1"><strong>Status:</strong> {selectedBill.status}</Typography>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Billing</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search billing"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedBill ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Dialog open={openConfirmDialog} onClose={closeDialogs}>
        <DialogTitle>{"Confirm Payment"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to pay Ksh {currentPayment.amount.toFixed(2)} for {currentPayment.item}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogs}>Cancel</Button>
          <Button onClick={confirmPayment} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={openAlertDialog} onClose={closeDialogs}>
        <DialogTitle>{"Payment Notification"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{alertMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogs} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BillingTable;
