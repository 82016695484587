import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Modal
} from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import AnimatedText from "./AnimatedText";
import logo from "../assets/images/logos/logo.jpg";
import SignIn from "../pages/SignIn";
const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerTop = isMobile ? "48px" : "64px";

  const handleSignInWithEmail = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    try {
      const response = await axios.get(
        "https://asset-management.accentrixcloud.com/api/accounts"
      );

      const accounts = response.data;

      // Create a regex and find the matching account
      const regex = new RegExp(email);
      const matchingAccount = accounts.find(
        (account) => account.email === email
      );
      console.log(matchingAccount);
      // Navigate based on user category
      if (matchingAccount) {
        if (matchingAccount.is_admin) {
          navigate("/macrobian", { state: { res_admin: matchingAccount } });
        } else if (matchingAccount.is_subscriber) {
          navigate("/home", {
            state: { res_serviceProvider: matchingAccount },
          });
        } else if (matchingAccount.is_active) {
          navigate("/user-profile", {
            state: { res_patient: matchingAccount },
          });
        }
      } else {
        alert("No matching account found or account is inactive.");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      alert(`An error occurred while signing in: ${error.message}`);
    }
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#303f9f" }}>
      <Toolbar>
        {/* Logo/Image next to the title */}
        <img
          src={logo}
          alt="Logo"
          style={{ height: "40px", marginRight: "10px" }}
        />

        {isMobile ? (
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Macrobian Therapy
          </Typography>
        ) : (
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Macrobian Therapy
          </Typography>
        )}

        {isMobile && (
          <IconButton color="inherit" onClick={toggleDrawer(true)}>
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
        )}

        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          sx={{ ".MuiDrawer-paper": { top: drawerTop } }}
        >
          {/* Drawer content */}
        </Drawer>

        {/* Content visible on desktop */}
        {!isMobile && (
          <>
            {/* Navigation Buttons */}
            <Button color="inherit" component={Link} to="/">
              Home
            </Button>
            <Button color="inherit" component={Link} to="/about-us">
              About Us
            </Button>
            <Button color="inherit" component={Link} to="/blog">
              Our Blog
            </Button>
            <Button color="inherit" component={Link} to="/corporates">
              Corporates
            </Button>
            <Button color="inherit" component={Link} to="/contacts">Contact Us</Button>

          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
