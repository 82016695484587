import React from 'react';
import { TreeView } from "@mui/x-tree-view";
import { TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';

const AdminMenu = ({ handleClientsViewClick, handleCalendarsViewClick, handleBlogPostsViewClick, handleResourceCentersViewClick, handleTicketsViewClick, handleProfileViewClick, handleAccountsViewClick, handleQuestionaireViewClick, handleBillingViewClick, handleDashboardViewClick, handleAppointmentsViewClick, handleAssessmentsViewClick, handleSessionsViewClick, handleTreatmentsViewClick, handlePostRecoveryViewClick, handleSubscriptionsViewClick}) => {
  return (
    <TreeView
    aria-label="file system navigator"
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpandIcon={<ChevronRightIcon />}
  >
    <TreeItem nodeId="1" label="Your Inbox">
      <TreeItem nodeId="2" onClick={handleDashboardViewClick} label="View" />
    </TreeItem>
    <TreeItem nodeId="11" label="Therapy">
        <TreeItem nodeId="12" onClick={handleQuestionaireViewClick}  label="Questions" />
        <TreeItem nodeId="17" onClick={handleAppointmentsViewClick} label="Appointments" />
        <TreeItem nodeId="18" onClick={handleSessionsViewClick} label="Sessions" />
        <TreeItem nodeId="19" onClick={handleAssessmentsViewClick} label="Assessments" />
        <TreeItem nodeId="20" onClick={handleTreatmentsViewClick} label="Treatment" />
        <TreeItem nodeId="21" onClick={handlePostRecoveryViewClick} label="Post Recovery" />
      </TreeItem>
    <TreeItem nodeId="28" label="Your Clients">
      <TreeItem nodeId="29" onClick={handleClientsViewClick} label="Patients" />
      <TreeItem nodeId="30" onClick={handleClientsViewClick} label="Service Providers" />
    </TreeItem>
    <TreeItem nodeId="31" label="Content Marketing">
      <TreeItem nodeId="32" onClick={handleBlogPostsViewClick} label="Blog Posts" />
    </TreeItem>  
    <TreeItem nodeId="3" label="Resource Centres">
      <TreeItem nodeId="4" onClick={handleResourceCentersViewClick} label="View" />
    </TreeItem>
    <TreeItem nodeId="24" label="User Feedback">
      <TreeItem nodeId="25" onClick={handleTicketsViewClick} label="View" />
    </TreeItem> 
    <TreeItem nodeId="13"  label="Billing">
      <TreeItem nodeId="14" onClick={handleBillingViewClick} label="View" />
    </TreeItem>
    <TreeItem nodeId="5" label="Subscriptions">
      <TreeItem nodeId="10" onClick={handleSubscriptionsViewClick} label="View" />      
    </TreeItem>
    <TreeItem nodeId="22" label="User Accounts">
      <TreeItem nodeId="23" onClick={handleAccountsViewClick} label="View" />
      <TreeItem nodeId="27" onClick={handleProfileViewClick} label="Your Profile" />
    </TreeItem>
    <TreeItem nodeId="15" label="Exit">
      <TreeItem nodeId="16" label={ <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}> Sign Out </Link> } />
    </TreeItem>
  </TreeView>
  );
};

export default AdminMenu;
