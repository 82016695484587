// FacebookSignIn.js
import React from 'react';
import { firebaseApp, facebookProvider } from "../firebaseConfig"; // Adjust the import path as necessary
import { getAuth, signInWithPopup } from 'firebase/auth';

const auth = getAuth(firebaseApp);

export const signInWithFacebook = async () => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    // Extract user information from the result
    const user = result.user;
    // Additional token if needed: const token = result.credential.accessToken;
    return user;
  } catch (error) {
    console.error('Error during Facebook Sign-In', error);
    throw error;
  }
};

const FacebookSignInButton = () => {
  const handleSignIn = async () => {
    try {
      const user = await signInWithFacebook();
      console.log(user);
      // Here, you could redirect the user or do any further processing
    } catch (error) {
      alert(`Facebook sign-in failed: ${error.message}`);
    }
  };

  return (
    <button onClick={handleSignIn}>Sign in with Facebook</button>
  );
};

export default FacebookSignInButton;
