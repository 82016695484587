import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Link,
  Modal,
  CircularProgress
} from "@mui/material";
import axios from "axios";

const SignIn = ({ handleCloseSignIn }) => {
  const navigate = useNavigate();
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", password: "", phone: "" });

  const handleOpenSignIn = () => {
    setOpenRegister(false);
    setOpenReset(false);
    setOpenSignIn(true);
    handleCloseSignIn();
  };
  

  const handleOpenRegister = () => {
    setOpenReset(false);
    handleCloseSignIn();
    setOpenRegister(true);
  };
  
  const handleCloseRegister = () => setOpenRegister(false);

  const handleOpenReset = () => {
    setOpenSignIn(false);
    setOpenRegister(false);
    setOpenReset(true);
  };
  
  const handleCloseReset = () => setOpenReset(false);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSignInWithEmail = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://therapy-api.accentrixcloud.com/auth", {
        email: formData.email,
        password: formData.password,
      });
      if (response.status === 200) {
        // Handle successful login here (e.g., store token, redirect user, etc.)
        navigate("/user-profile");
      }
    } catch (error) {
      console.error("There was an error signing in!", error);
      alert("Sign-in failed. Please check your email and password and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://your-api-endpoint.com/register", {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
      });
      if (response.status === 200) {
        // Handle successful registration here
        alert("Registration successful!");
        handleCloseRegister();
        handleOpenSignIn();
      }
    } catch (error) {
      console.error("There was an error registering!", error);
      alert("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://your-api-endpoint.com/reset-password", {
        email: formData.email,
      });
      if (response.status === 200) {
        // Handle successful password reset here
        alert("Password reset email sent!");
        handleCloseReset();
        handleOpenSignIn();
      }
    } catch (error) {
      console.error("There was an error resetting the password!", error);
      alert("Password reset failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    maxHeight: "90%",
  };

  return (
    <>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Please Sign In
          </Typography>
          <Box
            component="form"
            onSubmit={handleSignInWithEmail}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
            </Button>
            <Grid container justifyContent="space-between">
              <Grid item xs>
                <Link href="#" variant="body2" onClick={handleOpenReset}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" onClick={handleOpenRegister}>
                  Register here
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      <Modal open={openRegister} onClose={handleCloseRegister}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Please Register Your Account
          </Typography>
          <Box
            component="form"
            onSubmit={handleRegisterSubmit}
            sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="name"
              label="Name"
              name="name"
              onChange={handleChange}
            />
            <TextField
              required
              id="email"
              label="Email"
              name="email"
              type="email"
              onChange={handleChange}
            />
            <TextField
              required
              id="phone"
              label="Phone Number"
              name="phone"
              type="tel"
              onChange={handleChange}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, display: "block", backgroundColor: "#4caf50", alignSelf: "center" }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
            <Link href="#" variant="body2" onClick={handleOpenSignIn} sx={{ mt: 2, display: "block", textAlign: "center" }}>
              Already registered? Sign in here
            </Link>
          </Box>
        </Box>
      </Modal>

      <Modal open={openReset} onClose={handleCloseReset}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Reset Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleResetSubmit}
            sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="email"
              label="Email"
              name="email"
              type="email"
              onChange={handleChange}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, display: "block", backgroundColor: "#4caf50", alignSelf: "center" }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
            <Link href="#" variant="body2" onClick={handleOpenSignIn} sx={{ mt: 2, display: "block", textAlign: "center" }}>
              Back to Sign In
            </Link>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SignIn;
