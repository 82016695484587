import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const SuperuserUserAccounts = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formData, setFormData] = useState({ username: "", email: "", phone: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/api/accounts");
      setAccounts(response.data);
      setFilteredAccounts(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch accounts data");
      setLoading(false);
      setSnackbar({ open: true, message: "Failed to fetch accounts data", severity: "error" });
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAccounts(
      accounts.filter(
        (account) =>
          account.username.toLowerCase().includes(value) ||
          account.email.toLowerCase().includes(value)
      )
    );
  };

  const handleEditIconClick = (account) => {
    setSelectedAccount(account);
    setFormData({ username: account.username, email: account.email, phone: account.phone });
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (account) => {
    setSelectedAccount(account);
    setOpenDeleteModal(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!validateEmail(formData.email)) {
      setError("Invalid email format.");
      setIsSubmitting(false);
      setSnackbar({ open: true, message: "Invalid email format", severity: "error" });
      return;
    }
    try {
      await axios.put(`https://therapy-api.accentrixcloud.com/api/accounts/${selectedAccount.account_id}`, formData);
      fetchAccounts();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Account updated successfully", severity: "success" });
    } catch (error) {
      setError("Failed to update account data");
      setSnackbar({ open: true, message: "Failed to update account data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://therapy-api.accentrixcloud.com/api/account/${selectedAccount.account_id}`);
      fetchAccounts();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Account deleted successfully", severity: "success" });
    } catch (error) {
      setError("Failed to delete account data");
      setSnackbar({ open: true, message: "Failed to delete account data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!validateEmail(formData.email)) {
      setError("Invalid email format.");
      setIsSubmitting(false);
      setSnackbar({ open: true, message: "Invalid email format", severity: "error" });
      return;
    }
    try {
      await axios.post("https://therapy-api.accentrixcloud.com/api/accounts", formData);
      fetchAccounts();
      setOpenAddModal(false);
      setSnackbar({ open: true, message: "Account created successfully", severity: "success" });
    } catch (error) {
      setError("Failed to add account");
      setSnackbar({ open: true, message: "Failed to add account", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleResendActivation = async (userId) => {
    try {
      await axios.post(`http://34.30.141.248:5000/api/resend-activation/${userId}`);
      setSnackbar({ open: true, message: "Activation link sent!", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to send activation link. Please try again.", severity: "error" });
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date Created</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAccounts.map((account) => (
            <TableRow key={account.account_id}>
              <TableCell>{new Date(account.created_at).toLocaleString()}</TableCell>
              <TableCell>{account.username}</TableCell>
              <TableCell>{account.email}</TableCell>
              <TableCell>{account.phone}</TableCell>
              <TableCell>
                {account.is_active === 0 ? (
                  <>
                    Inactive <IconButton onClick={() => handleResendActivation(account.account_id)}><FontAwesomeIcon icon={faRecycle} color="red" /></IconButton>
                  </>
                ) : (
                  "Active"
                )}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleEditIconClick(account)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteIconClick(account)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>User Accounts</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search accounts"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setFormData({ username: "", email: "", phone: "" });
            setOpenAddModal(true);
          }}
          sx={{ ml: 2, paddingBottom: 1 }}
        >
          Add User
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredAccounts.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No user accounts found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>Edit Account</Typography>
          <Box component="form" onSubmit={handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Name"
              name="username"
              value={formData.username || ""}
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
            />
            <TextField
              margin="normal"
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              value={formData.phone || ""}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>Create Account</Typography>
          <Box component="form" onSubmit={handleAddSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Name"
              name="username"
              value={formData.username || ""}
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email || ""}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <TextField
              margin="normal"
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              value={formData.phone || ""}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this user account?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserUserAccounts;
