import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
  IconButton,
  Badge,
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faTasks,
  faDollarSign,
  faComments,
  faUserMd,
  faCartPlus,
  faMapMarkerAlt,
  faEnvelopeOpenText,
  faCalendarCheck,
  faFileAlt,
  faUserCircle,
  faArrowLeft,
  faSignOutAlt,
  faCog,
  faQuestionCircle,
  faMap,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import UserAppointment from "../components/UserAppointment";
import UserAssessment from "../components/UserAssessment";
import UserSessions from "../components/UserSessions";
import UserTreatments from "../components/UserTreatments";
import UserPostRecovery from "../components/UserPostRecovery";
import UserSubscriptions from "../components/UserSubscriptions";
import { UserContext } from "../UserContext";

const UserInbox = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [selectedComponent, setSelectedComponent] = useState(null);

  const icons = [
    { name: "Appointments", icon: faCalendarAlt, component: 'Appointments', badgeCount: 5 },
    { name: "Responses", icon: faComments, component: 'Responses', badgeCount: 3 },
    { name: "Billing", icon: faDollarSign, component: 'Billing', badgeCount: 7 },
    { name: "Sessions", icon: faUserMd, component: 'Sessions', badgeCount: 2 },
    { name: "Treatments", icon: faTasks, component: 'Treatments', badgeCount: 4 },
    { name: "Post Recovery", icon: faCalendarCheck, component: 'PostRecovery', badgeCount: 6 },
    { name: "Assessments", icon: faFileAlt, component: 'Assessments', badgeCount: 3 },
    { name: "Feedback", icon: faCommentDots, component: 'Feedback', badgeCount: 4 },
    { name: "Our Shop", icon: faCartPlus, component: 'OurShop', badgeCount: 6 },
    { name: "Calendar", icon: faCalendarAlt, component: 'Calendar', badgeCount: 2 },
    { name: "Map", icon: faMap, component: 'Map', badgeCount: 0 },
    { name: "Chat", icon: faEnvelopeOpenText, component: 'Chat', badgeCount: 5 },
    { name: "Help", icon: faQuestionCircle, component: 'Help', badgeCount: 0 },
    { name: "Settings", icon: faCog, component: 'Settings', badgeCount: 0 },
    { name: "Logout", icon: faSignOutAlt, component: 'Logout', badgeCount: 0 },
  ];

  useEffect(() => {
    setLoading(false);  // Simulating loading finished
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/login');
    setSnackbar({ open: true, message: 'Logged out successfully', severity: 'success' });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const renderIcons = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: 'center' }}>
          <Badge badgeContent={icon.badgeCount} color="secondary">
            <IconButton onClick={() => {
              if (icon.component === 'Logout') {
                handleLogout();
              } else {
                setSelectedComponent(icon.component);
              }
            }} sx={{ fontSize: 40, color: 'primary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)', color: 'secondary.main' } }}>
              <FontAwesomeIcon icon={icon.icon} />
            </IconButton>
          </Badge>
          <Typography variant="body1">{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Appointments':
        return <UserAppointment />;
      case 'Responses':
        return <UserAssessment />;
      case 'Billing':
        return <UserSubscriptions />;
      case 'Sessions':
        return <UserSessions />;
      case 'Treatments':
        return <UserTreatments />;
      case 'PostRecovery':
        return <UserPostRecovery />;
      case 'Assessments':
        return <UserAssessment />;
      case 'Feedback':
        return <UserAssessment />;
      case 'OurShop':
        return <UserAssessment />;
      case 'Calendar':
        return <UserAssessment />;
      case 'Map':
        return <UserAssessment />;
      case 'Chat':
        return <UserAssessment />;
      case 'Help':
        return <UserAssessment />;
      case 'Settings':
        return <UserAssessment />;
      default:
        return renderIcons();
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {selectedComponent && (
          <Link underline="hover" color="inherit" onClick={() => setSelectedComponent(null)} style={{ cursor: 'pointer' }}>
            Home
          </Link>
        )}
        {selectedComponent && (
          <Typography color="text.primary">{selectedComponent}</Typography>
        )}
        {!selectedComponent && (
          <Typography color="text.primary">Home</Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        renderComponent()
      )}
      {selectedComponent && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <IconButton onClick={() => setSelectedComponent(null)} sx={{ fontSize: 40, color: 'primary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)', color: 'secondary.main' } }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        </Box>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserInbox;
