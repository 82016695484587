import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon
} from "@mui/icons-material";
import axios from "axios";

const UserSubscriptions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/sessions");
      setSubscriptions(response.data);
      setFilteredSubscriptions(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch subscriptions", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSubscriptions(
      subscriptions.filter(subscription =>
        subscription.subscription.toLowerCase().includes(value) ||
        subscription.status.toLowerCase().includes(value) ||
        subscription.period.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (subscription) => {
    setSelectedSubscription(subscription);
  };

  const handleBackClick = () => {
    setSelectedSubscription(null);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Subscription</TableCell>
            <TableCell>Period</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSubscriptions.length > 0 ? (
            filteredSubscriptions.map((subscription) => (
              <TableRow key={subscription.id} onClick={() => handleRowClick(subscription)}>
                <TableCell>{new Date(subscription.date).toLocaleDateString()}</TableCell>
                <TableCell>{subscription.subscription}</TableCell>
                <TableCell>{subscription.period}</TableCell>
                <TableCell>{subscription.status}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleRowClick(subscription)}>
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No subscription records available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Subscription Details</Typography>
      <Typography variant="body1"><strong>Date:</strong> {new Date(selectedSubscription.date).toLocaleDateString()}</Typography>
      <Typography variant="body1"><strong>Subscription:</strong> {selectedSubscription.subscription}</Typography>
      <Typography variant="body1"><strong>Period:</strong> {selectedSubscription.period}</Typography>
      <Typography variant="body1"><strong>Status:</strong> {selectedSubscription.status}</Typography>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Subscriptions</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search subscriptions"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedSubscription ? (
        renderDetails()
      ) : (
        renderTable()
      )}
    </Container>
  );
};

export default UserSubscriptions;
