import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Paper,
  Typography,
  Grid,
  Modal,
  Button,
  Breadcrumbs
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";

const navbarHeight = 64;

const bannerStyle = {
  display: "flex",
  flexDirection: "column",
  marginTop: `${navbarHeight}px`,
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: 'url("https://source.unsplash.com/random?privacy")',
  position: "relative",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const textStyle = {
  zIndex: 2,
  color: "white",
  fontSize: "48px",
  position: "relative",
};

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <div style={{backgroundColor: "#e1f5fe"}}>
      <Navbar />
      <div style={bannerStyle}>
        <div style={overlayStyle}></div>
        <Typography variant="h3" style={textStyle}>
          Privacy Policy
        </Typography>
      </div>
      <Breadcrumbs style={{padding: '20px'}} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Privacy Policy</Typography>
        </Breadcrumbs>
      <Paper
        style={{
          padding: 20,
          backgroundColor: "#f5f5f5",
          color: "#000",
          margin: "20px auto",
          maxWidth: "800px"
        }}
      >
        
        <Typography variant="h6" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our platform.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect personal information such as name, email address, and other necessary details for account registration and service provision.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Use of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use your information to provide, operate, and maintain our services, communicate with you, and improve our platform.
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. Disclosure of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information with third-party service providers to perform functions on our behalf. We do not sell or rent your personal information to third parties.
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. Security of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We employ administrative, technical, and physical security measures to protect your personal information. However, no security system is impenetrable.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. Your Data Protection Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, update, and delete your personal information. You can do so by contacting us directly.
        </Typography>
        <Typography variant="h6" gutterBottom>
          6. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
        </Typography>
        <Typography variant="h6" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this privacy policy, please contact us.
        </Typography>
        
      </Paper>
      
      <Paper
        style={{
          padding: 20,
          backgroundColor: "#e1f5fe",
          color: "#FFF",
        }}
      >
        <Grid container spacing={2} mt={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#7986cb" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#7986cb" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy-policy"
              >
                Privacy
              </Typography>
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/terms"
              >
                Terms of Use
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default PrivacyPolicy;
