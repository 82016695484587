// SuperuserSubscriptions.js

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
  Button
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import axios from "axios";

const SuperuserSubscriptions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/subscriptions");
      setSubscriptions(response.data);
      setFilteredSubscriptions(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch subscriptions data");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSubscriptions(
      subscriptions.filter(
        (subscription) =>
          subscription.name.toLowerCase().includes(value) ||
          subscription.email.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (subscription) => {
    setSelectedSubscription(subscription);
    setOpenDetailModal(true);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSubscriptions.map((subscription) => (
            <TableRow key={subscription.id} onClick={() => handleRowClick(subscription)}>
              <TableCell>{new Date(subscription.date).toLocaleString()}</TableCell>
              <TableCell>{subscription.name}</TableCell>
              <TableCell>{subscription.email}</TableCell>
              <TableCell>{subscription.phone}</TableCell>
              <TableCell>{subscription.amount}</TableCell>
              <TableCell>{subscription.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Subscriptions</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search subscriptions"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredSubscriptions.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No subscriptions found.
        </Typography>
      )}

      <Modal open={openDetailModal} onClose={() => setOpenDetailModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {selectedSubscription && (
            <>
              <Typography variant="h6" gutterBottom>Subscription Details</Typography>
              <Typography variant="body1"><strong>Name:</strong> {selectedSubscription.name}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {selectedSubscription.email}</Typography>
              <Typography variant="body1"><strong>Phone:</strong> {selectedSubscription.phone}</Typography>
              <Typography variant="body1"><strong>Amount:</strong> {selectedSubscription.amount}</Typography>
              <Typography variant="body1"><strong>Status:</strong> {selectedSubscription.status}</Typography>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setOpenDetailModal(false)}
                sx={{ mt: 1 }}
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>

      <Modal open={Boolean(error)} onClose={() => setError("")}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
          <Button onClick={() => setError("")} color="primary">
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserSubscriptions;
