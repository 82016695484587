import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  useMediaQuery,
  useTheme,
  Toolbar,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon, Payment as PaymentIcon } from "@mui/icons-material";
import axios from "axios";

const ServiceProviderSubscriptions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/service-provider-subscriptions");
      setSubscriptions(response.data);
      setFilteredSubscriptions(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch service provider subscriptions");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSubscriptions(
      subscriptions.filter(
        (subscription) =>
          subscription.name.toLowerCase().includes(value) ||
          subscription.email.toLowerCase().includes(value) ||
          subscription.phoneNumber.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenAddModal = () => {
    setFormData({});
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setFormData({});
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post(`https://therapy-api.accentrixcloud.com/service-provider-subscriptions`, formData);
      setSuccess("Service provider subscription added successfully!");
      fetchSubscriptions();
      handleCloseAddModal();
    } catch (error) {
      setError("Failed to add service provider subscription");
      setLoading(false);
    }
  };

  const handleCloseError = () => setError("");
  const handleCloseSuccess = () => setSuccess("");

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSubscriptions.map((subscription) => (
            <TableRow key={subscription.id}>
              <TableCell>{new Date(subscription.date).toLocaleString()}</TableCell>
              <TableCell>{subscription.name}</TableCell>
              <TableCell>{subscription.email}</TableCell>
              <TableCell>{subscription.phoneNumber}</TableCell>
              <TableCell>{`$${subscription.amount.toFixed(2)}`}</TableCell>
              <TableCell>{subscription.status}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PaymentIcon />}
                  onClick={() => handleOpenAddModal(subscription)}
                >
                  Pay
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Service Provider Subscriptions</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search subscriptions"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredSubscriptions.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No subscriptions found.
        </Typography>
      )}

      <Modal open={openAddModal} onClose={handleCloseAddModal} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            Add Payment
          </Typography>
          <Box component="form" onSubmit={handleAddSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.name || ""}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
              type="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber || ""}
              onChange={handleChange}
              type="tel"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="amount"
              label="Amount"
              name="amount"
              value={formData.amount || ""}
              onChange={handleChange}
              type="number"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="status"
              label="Status"
              name="status"
              value={formData.status || ""}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={Boolean(error)} onClose={handleCloseError}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
          <Button onClick={handleCloseError} color="primary">
            OK
          </Button>
        </Box>
      </Modal>

      <Modal open={Boolean(success)} onClose={handleCloseSuccess}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Success
          </Typography>
          <Typography variant="body1">
            {success}
          </Typography>
          <Button onClick={handleCloseSuccess} color="primary">
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default ServiceProviderSubscriptions;
