import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import axios from 'axios';

const CorporateBilling = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [selectedBill, setSelectedBill] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      // Replace the URL with your actual endpoint
      const response = await axios.get("https://therapy-api.accentrixcloud.com/corporate_bills");
      setBills(response.data);
      setFilteredBills(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch bills", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBills(
      bills.filter(bill =>
        bill.item.toLowerCase().includes(value) ||
        bill.status.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (bill) => {
    setSelectedBill(bill);
  };

  const handleBackClick = () => {
    setSelectedBill(null);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Total Cost</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBills.length > 0 ? (
            filteredBills.map((bill) => (
              <TableRow key={bill.id} onClick={() => handleRowClick(bill)}>
                <TableCell>{new Date(bill.date).toLocaleDateString()}</TableCell>
                <TableCell>{bill.item}</TableCell>
                <TableCell>{`Ksh ${bill.total_cost.toFixed(2)}`}</TableCell>
                <TableCell>{bill.status}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No corporate billing records available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Invoice Details</Typography>
      <Typography variant="body1"><strong>Date:</strong> {new Date(selectedBill.date).toLocaleDateString()}</Typography>
      <Typography variant="body1"><strong>Item:</strong> {selectedBill.item}</Typography>
      <Typography variant="body1"><strong>Total Cost:</strong> {`Ksh ${selectedBill.total_cost.toFixed(2)}`}</Typography>
      <Typography variant="body1"><strong>Status:</strong> {selectedBill.status}</Typography>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Corporate Billing</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search billing"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedBill ? (
        renderDetails()
      ) : (
        renderTable()
      )}
    </Container>
  );
};

export default CorporateBilling;
