import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const defaultCenter = { lat: 1.2921, lng: 36.8219 }; // Default location

const Map = ({ resourceCenters =[], userLocation }) => {
  const [currentLocation, setCurrentLocation] = useState(userLocation || null);
  const [selectedResource, setSelectedResource] = useState(null);

  useEffect(() => {
    // Check for geolocation support
    if (!userLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude }),
        (error) => console.error('Error getting user location:', error)
      );
    } else if (userLocation) {
      setCurrentLocation(userLocation);
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, [userLocation]);

  const handleMarkerClick = (resource) => {
    setSelectedResource(resource);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCP-eSkk0KC2cC8BWvjtBFZY66DztBTvRo">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={currentLocation || defaultCenter}
        zoom={10}
      >
        {currentLocation && <Marker position={currentLocation} />}
        {resourceCenters.map((resource, index) => (
          <Marker
            key={index}
            position={{ lat: resource.latitude, lng: resource.longitude }}
            onClick={() => handleMarkerClick(resource)}
          />
        ))}
        {selectedResource && (
          <InfoWindow
            position={{ lat: selectedResource.latitude, lng: selectedResource.longitude }}
            onCloseClick={() => setSelectedResource(null)}
          >
            <div>
              <h3>{selectedResource.center}</h3>
              <p>{selectedResource.description}</p>
              {/* Add more details as needed */}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
