import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Cancel as CancelIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';

const ServiceProviderTherapyAssessments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [assessments, setAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    date: '',
    name: '',
    email: '',
    status: ''
  });

  useEffect(() => {
    fetchAssessments();
  }, []);

  const fetchAssessments = async () => {
    try {
      const response = await axios.get('https://therapy-api.accentrixcloud.com/service-provider-assessments');
      setAssessments(response.data);
      setFilteredAssessments(response.data);
      setLoading(false);
    } catch (error) {
      handleError('Failed to fetch assessments');
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAssessments(
      assessments.filter(
        (assessment) =>
          assessment.name.toLowerCase().includes(value) ||
          assessment.email.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenDetailsModal = (assessment) => {
    setSelectedAssessment(assessment);
    setFormData(assessment);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedAssessment(null);
    setFormData({
      date: '',
      name: '',
      email: '',
      status: ''
    });
  };

  const handleOpenAddEditModal = (assessment = null) => {
    setSelectedAssessment(assessment);
    setFormData(assessment || {
      date: '',
      name: '',
      email: '',
      status: ''
    });
    setOpenAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setOpenAddEditModal(false);
    setSelectedAssessment(null);
    setFormData({
      date: '',
      name: '',
      email: '',
      status: ''
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    try {
      if (selectedAssessment) {
        await axios.put(`/assessments/${selectedAssessment.id}`, formData);
      } else {
        await axios.post('/assessments', formData);
      }
      fetchAssessments();
      handleCloseAddEditModal();
    } catch (error) {
      handleError('Failed to save assessment');
    }
  };

  const handleDelete = async (assessmentId) => {
    try {
      await axios.delete(`/assessments/${assessmentId}`);
      fetchAssessments();
    } catch (error) {
      handleError('Failed to delete assessment');
    }
  };

  const handleCancel = async (assessmentId) => {
    try {
      await axios.put(`/assessments/${assessmentId}`, { status: 'Cancelled' });
      fetchAssessments();
    } catch (error) {
      handleError('Failed to cancel assessment');
    }
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAssessments.length > 0 ? (
            filteredAssessments.map((assessment) => (
              <TableRow key={assessment.id} onClick={() => handleOpenDetailsModal(assessment)}>
                <TableCell>{new Date(assessment.date).toLocaleString()}</TableCell>
                <TableCell>{assessment.name}</TableCell>
                <TableCell>{assessment.email}</TableCell>
                <TableCell>{assessment.status}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleOpenAddEditModal(assessment); }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleCancel(assessment.id); }}>
                    <CancelIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(assessment.id); }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No assessments available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleCloseDetailsModal}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Assessment Details</Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="date"
          label="Date"
          name="date"
          type="datetime-local"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Service Provider Therapy Assessments</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search assessments"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenAddEditModal()}
          sx={{ ml: 2 }}
        >
          Add Assessment
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedAssessment ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openAddEditModal} onClose={handleCloseAddEditModal}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {renderDetails()}
        </Box>
      </Modal>

      <Dialog open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorModal(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default ServiceProviderTherapyAssessments;
