import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import IconMarquee from "./IconMarquee";
import Navbar from "./Navbar";
import SocialMediaLinks from "./SocialMediaLinks";
import FlipAnimation from "./FlipAnimation";
import "./SliderStyles.css";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CorporateMenu from "./CorporateMenu";
import CorporateSubscriptions from "./CorporateSubscriptions";
import TicketModal from "./TicketModal";


const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const services = [
    { name: "Inbox", description: "Your central place for notifications and messages.", icon: "mail", path: "/inbox" },
    { name: 'Consultations', description: 'Paid outside work', icon: 'people_outline', path: '/consultations' },
    { name: 'Observations', description: 'Internal work clients pay for', icon: 'visibility', path: '/observations' },
    { name: 'Referrals', description: 'Refer to lab, radiology, dentist, etc.', icon: 'send', path: '/referrals' },  
  {
    name: "Therapy",
    description: "Professional counselling services.",
    icon: "psychology",
    path: "/therapy",
  },
  {
    name: "Pharmacy",
    description: "Pharmacy services for prescriptions.",
    icon: "local_pharmacy",
    path: "/pharmacy",
  },
 
  { name: "Our Team", description: "Meet your dedicated team of healthcare professionals.", icon: "group", path: "/our-team" },
  { name: "Integrations", description: "Seamlessly integrate with other healthcare systems.", icon: "integration_instructions", path: "/integrations" },
  // New services added
  { name: "Financials", description: "Overview of financial operations and billing.", icon: "attach_money", path: "/finances" },
  { name: "Master Data", description: "Manage and access critical master data.", icon: "data_object", path: "/master-data" },
  { name: "Appointments", description: "Schedule and manage appointments efficiently.", icon: "event_available", path: "/appointments" },
  { name: "CRM", description: "Manage customer relationships and interactions.", icon: "people_alt", path: "/crm" },
  // Adding new services
  { name: "Crowdfunding", description: "Support and initiate healthcare crowdfunding campaigns.", icon: "volunteer_activism", path: "/crowdfunding" },
  { name: "Polls", description: "Engage with polls to make informed decisions.", icon: "poll", path: "/polls" },


  // Add more services as needed
];

const ViewCorporateSubscriptions = () => {
 
  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);


  const handleSignInWithEmail = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");

    try {
      const response = await axios.get(
        "https://asset-management.accentrixcloud.com/api/accounts"
      );

      const accounts = response.data;

      // Create a regex and find the matching account
      const regex = new RegExp(email);
      const matchingAccount = accounts.find((account) =>
        regex.test(account.email)
      );

      // Navigate based on user category
      if (matchingAccount.is_admin) {
        navigate("/macrobian", { state: { res_admin: matchingAccount } });
      } else if (matchingAccount.subscriber) {
        navigate("/home", { state: { res_superuser: matchingAccount } });
      } else {
        navigate("/user-profile", {
          state: { res_superuser: matchingAccount },
        });
      }
    } catch (error) {
      console.error("Sign in error:", error);
      alert(`An error occurred while signing in: ${error.message}`);
    }
  };

  const handleBillingViewClick = () => {
    navigate('/corporate-billing');
};

const handleDashboardViewClick = () => {
    navigate('/corporate-profile');
};

const handleQuestionaireViewClick = () => {
    navigate('/corporate-questionaire');
};

const handleStaffViewClick = () => {
  navigate('/corporate-staff');
};

const handlePostRecoveryViewClick = () => {
  navigate('/corporate-post-recovery');
};

const handleSubscriptionsViewClick = () => {
  navigate('/corporate-subscriptions');
};

const handleProfileViewClick = () => {
  navigate('/corporate-user');
};


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Navbar />
      <Paper
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          marginTop: `${navbarHeight}px`,
          padding: 20,
          backgroundColor: "#1B277D",
          color: "#FFF",
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex", height: "calc(100vh - 64px)" }}>
          <Box
            sx={{
              width: { xs: 0, sm: "200px" }, // Adjust width as needed
              overflowY: "auto",
            }}
          >
            {/* Dashboard and TreeView go here */}
            <Typography variant="h6" style={{ padding: "16px" }}>
              Dashboard
            </Typography>
            {/* TreeView component */}
            <CorporateMenu handleProfileViewClick={handleProfileViewClick} handleSubscriptionsViewClick={handleSubscriptionsViewClick} handlePostRecoveryViewClick={handlePostRecoveryViewClick} handleBillingViewClick={handleBillingViewClick} handleDashboardViewClick={handleDashboardViewClick} handleQuestionaireViewClick={handleQuestionaireViewClick} handleStaffViewClick={handleStaffViewClick} />
          </Box>
          <Box sx={{ flex: 1, overflowY: "auto" }}>
            {/* Main content goes here */}
            <Paper
              elevation={3}
              style={{
                margin: "16px",
                padding: "16px",
                flexGrow: 1,
                overflow: "auto",
                height: "calc(100vh - 180px - 32px)",
                backgroundColor: "#b2dfdb",
              }}
            >
              <CorporateSubscriptions />
            </Paper>
          </Box>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#00e5ff" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#fff" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography color="#00e5ff" style={{ textAlign: "center" }}>
              Do you need any support?
            </Typography>
            <Button
              variant="contained"
              style={{
                margin: "auto",
                display: "block",
                backgroundColor: "#4caf50",
              }}
              onClick={handleOpen}
            >
              Talk To Us
            </Button>
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <TicketModal open={openModal} onClose={handleClose} />
            </Modal>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
              <SpeedDial
                ariaLabel="SpeedDial"
                sx={{
                  "& .MuiFab-primary": {
                    backgroundColor: "#4caf50",
                    "&:hover": { backgroundColor: "#43a047" },
                  },
                }}
                icon={<SpeedDialIcon />}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                  />
                ))}
              </SpeedDial>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ViewCorporateSubscriptions;
