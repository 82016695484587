import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Tab,
  Box,
} from "@mui/material";

function GeneralQuestionnaire() {
  const [answers, setAnswers] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    group1: "",
    group2: "",
    group3: "",
    groupExpectation: "",
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event) => {
    setAnswers({ ...answers, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpenConfirmDialog(true);
  };

  const handleConfirmSubmit = async () => {
    setOpenConfirmDialog(false);
    // Simulate a submission delay
    setTimeout(() => {
      console.log(answers); // Simulate API call
      setOpenSubmitDialog(true);
    }, 1000);
  };

  const handleCloseSubmitDialog = () => {
    setOpenSubmitDialog(false);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const textFieldStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: "#ffffff",
    marginBottom: "8px",
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        backgroundColor: "#1B277D",
        color: "#ffffff",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h6" gutterBottom style={{ color: "#ffffff" }}>
        Therapy Questionnaire
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="questionnaire tabs"
        style={{ marginBottom: "20px" }}
      >
        <Tab label="Personal Questions" />
        <Tab label="Group Therapy Questions" />
        <Tab label="Sign & Submit" />
      </Tabs>
      <form onSubmit={handleSubmit}>
        {tabIndex === 0 && (
          <Box>
            <TextField
              fullWidth
              label="Have you ever talked to a mental health professional about an emotional problem?"
              variant="filled"
              InputProps={{
                style: textFieldStyle,
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              name="question1"
              value={answers.question1}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Have you ever felt you needed help with emotional problems?"
              variant="filled"
              InputProps={{
                style: textFieldStyle,
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              name="question2"
              value={answers.question2}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Have you ever been advised to take medication for an emotional problem?"
              variant="filled"
              InputProps={{
                style: textFieldStyle,
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              name="question3"
              value={answers.question3}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="How has your mental health been impacting your life?"
              variant="filled"
              InputProps={{
                style: textFieldStyle,
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              name="question4"
              value={answers.question4}
              onChange={handleChange}
            />
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{ color: "#ffffff", marginTop: "20px" }}
            >
              Group Therapy Questionnaire
            </Typography>
            <TextField
              fullWidth
              label="Where else might you have been if you hadn’t come to this group session today?"
              variant="filled"
              InputProps={{
                style: textFieldStyle,
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              name="group1"
              value={answers.group1}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="What might you have chosen to do instead of coming here?"
              variant="filled"
              InputProps={{
                style: textFieldStyle,
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              name="group2"
              value={answers.group2}
              onChange={handleChange}
            />
            <FormLabel
              component="legend"
              style={{ color: "#ffffff", marginTop: "20px" }}
            >
              Is it your own decision to come here today?
            </FormLabel>
            <RadioGroup
              name="group3"
              value={answers.group3}
              onChange={handleChange}
              row
              style={{ color: "#ffffff" }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            <TextField
              fullWidth
              label="What do you expect to get out of the group?"
              variant="filled"
              InputProps={{
                style: textFieldStyle,
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
              name="groupExpectation"
              value={answers.groupExpectation}
              onChange={handleChange}
            />
          </Box>
        )}
        {tabIndex === 2 && (
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{ color: "#ffffff", marginTop: "20px" }}
            >
              Sign & Submit
            </Typography>
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#0a4a7f", color: "#ffffff" }}
            >
              Submit
            </Button>
          </Box>
        )}
      </form>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>{"Confirm Submission"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to submit this questionnaire?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>No</Button>
          <Button onClick={handleConfirmSubmit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSubmitDialog} onClose={handleCloseSubmitDialog}>
        <DialogTitle>{"Submission Successful"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your data has been submitted. Please wait for a response.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSubmitDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default GeneralQuestionnaire;
