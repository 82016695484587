import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Modal
} from "@mui/material";
import Navbar from "../components/Navbar";
import logo from "../assets/images/logos/logo.jpg"; // Ensure you have the logo image

const AdminLogin = () => {
  const navigate = useNavigate();
  const navbarHeight = 64;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [resetEmail, setResetEmail] = useState("");
  const [showReset, setShowReset] = useState(false);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleResetChange = (event) => {
    setResetEmail(event.target.value);
  };

  const handleSignInWithEmail = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("/auth/login", formData);
      const account = response.data;
      if (account.is_admin) {
        navigate("/admin-profile", { state: { res_admin: account } });
      } else {
        navigate("/user-profile", { state: { res_superuser: account } });
      }
    } catch (error) {
      setError("An error occurred while signing in. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post("/auth/reset-password", { email: resetEmail });
      setShowReset(false);
    } catch (error) {
      setError("Failed to send password reset email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Navbar />

      <Paper
        style={{
          padding: 20,
          backgroundColor: "#e1f5fe",
          color: "#4caf50",
          flexGrow: 1,
          marginTop: navbarHeight,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!showReset ? (
          <Box
            sx={{
              width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 2,
              overflowY: "auto",
              maxHeight: "90vh",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={logo} alt="Logo" style={{ display: "block", marginBottom: "20px", maxWidth: "80px" }} />
            <Typography id="modal-modal-title" variant="h6" component="h4" align="center">
              Please Sign In
            </Typography>
            <Box
              component="form"
              onSubmit={handleSignInWithEmail}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#4caf50" }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
              </Button>
              <Grid container justifyContent="space-between">
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => setShowReset(true)}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflowY: "auto",
              maxHeight: "90vh",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={logo} alt="Logo" style={{ display: "block", marginBottom: "20px", maxWidth: "80px" }} />
            <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleResetSubmit}
              sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                required
                id="email"
                label="Email"
                name="email"
                type="email"
                value={resetEmail}
                onChange={handleResetChange}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "#4caf50" }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                </Button>
              </Box>
              <Link href="#" variant="body2" onClick={() => setShowReset(false)} sx={{ mt: 2, display: "block", textAlign: "center" }}>
                Back to Sign In
              </Link>
            </Box>
          </Box>
        )}

        {error && (
          <Modal open={Boolean(error)} onClose={() => setError("")}>
            <Box
              sx={{
                width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                overflowY: "auto",
                maxHeight: "90vh",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Error
              </Typography>
              <Typography variant="body1">
                {error}
              </Typography>
              <Button onClick={() => setError("")} color="primary">
                OK
              </Button>
            </Box>
          </Modal>
        )}
      </Paper>
    </div>
  );
};

export default AdminLogin;
