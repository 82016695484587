import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
  IconButton,
  Paper,
  Badge,
  Modal,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTasks, faDollarSign, faUsers, faComments, faUserMd, faCartPlus, faBlog, faMapMarkerAlt, faEnvelopeOpenText, faCalendarCheck, faFileAlt, faUserCircle, faArrowLeft, faSignOutAlt, faCog, faRecycle } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../UserContext';  // Assuming you have this context

import SuperuserAppointments from "../components/SuperuserAppointments";
import SuperuserTherapyTreatment from "../components/SuperuserTherapyTreatment";
import SuperuserTherapyAssessments from "../components/SuperuserTherapyAssessments";
import SuperuserUserAccounts from "../components/SuperuserUserAccounts";
import SuperuserTickets from "../components/SuperuserTickets";
import SuperuserQuestionnaires from "../components/SuperuserQuestionnaires";
import SuperuserSettings from "../components/SuperuserSettings";
import SuperuserResourceCenters from "../components/SuperuserResourceCenters";
import SuperuserBlogPosts from "../components/SuperuserBlogPosts";
import SuperuserCalendars from "../components/SuperuserCalendars";
import SuperuserSubscriptions from "../components/SuperuserSubscriptions";
import SuperuserBilling from "../components/SuperuserBilling";
import SuperuserClients from "../components/SuperuserClients";

const AdminInbox = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const icons = [
    { name: "Appointments", icon: faCalendarAlt, component: 'Appointments', badgeCount: 5 },
    { name: "Responses", icon: faComments, component: 'Responses', badgeCount: 3 },
    { name: "Billing", icon: faDollarSign, component: 'Billing', badgeCount: 7 },
    { name: "Treatments", icon: faTasks, component: 'Treatments', badgeCount: 4 },
    { name: "Blog", icon: faBlog, component: 'Blog', badgeCount: 1 },
    { name: "Subscriptions", icon: faCartPlus, component: 'Subscriptions', badgeCount: 8 },
    { name: "Resource Centers", icon: faMapMarkerAlt, component: 'ResourceCenters', badgeCount: 0 },
    { name: "Map", icon: faMapMarkerAlt, component: 'Map', badgeCount: 2 },
    { name: "Chat", icon: faEnvelopeOpenText, component: 'Chat', badgeCount: 5 },
    { name: "Assessments", icon: faFileAlt, component: 'Assessments', badgeCount: 3 },
    { name: "Feedback", icon: faEnvelopeOpenText, component: 'Feedback', badgeCount: 4 },
    { name: "Our Shop", icon: faCartPlus, component: 'OurShop', badgeCount: 6 },
    { name: "Calendar", icon: faCalendarAlt, component: 'Calendar', badgeCount: 2 },
    { name: "Clients", icon: faUsers, component: 'Clients', badgeCount: 7 },
    { name: "User Accounts", icon: faUserCircle, component: 'UserAccounts', badgeCount: 1 },
    { name: "Settings", icon: faCog, component: 'Settings', badgeCount: 0 },
    { name: "Logout", icon: faSignOutAlt, component: 'Logout', badgeCount: 0 },
  ];

  useEffect(() => {
    setLoading(false);  // Simulating loading finished
  }, []);

  const handleLogout = () => {
    axios.post('/logout')
      .then(() => {
        navigate('/login');
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Logout failed. Please try again.', severity: 'error' });
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleOpenDeleteModal = (item) => {
    setSelectedItem(item);
    setDeleteModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedItem(null);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedItem(null);
  };

  const handleSubmitEdit = async () => {
    setIsSubmitting(true);
    try {
      await axios.put(`/api/edit/${selectedItem.id}`, selectedItem);  // Adjust the endpoint as necessary
      setSnackbar({ open: true, message: 'Edit successful!', severity: 'success' });
      handleCloseEditModal();
    } catch (error) {
      setSnackbar({ open: true, message: 'Edit failed. Please try again.', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`/api/delete/${selectedItem.id}`);  // Adjust the endpoint as necessary
      setSnackbar({ open: true, message: 'Delete successful!', severity: 'success' });
      handleCloseDeleteModal();
    } catch (error) {
      setSnackbar({ open: true, message: 'Delete failed. Please try again.', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendActivation = async (userId) => {
    try {
      await axios.post(`/api/resend-activation/${userId}`);  // Adjust the endpoint as necessary
      setSnackbar({ open: true, message: 'Activation link sent!', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to send activation link. Please try again.', severity: 'error' });
    }
  };

  const renderIcons = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: 'center' }}>
          <Badge badgeContent={icon.badgeCount} color="secondary">
            <IconButton onClick={() => {
              if (icon.component === 'Logout') {
                handleLogout();
              } else {
                setSelectedComponent(icon.component);
              }
            }} sx={{ fontSize: 40, color: 'primary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)', color: 'secondary.main' } }}>
              <FontAwesomeIcon icon={icon.icon} />
            </IconButton>
          </Badge>
          <Typography variant="body1">{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Appointments':
        return <SuperuserAppointments />;
      case 'Responses':
        return <SuperuserTherapyAssessments />;
      case 'Billing':
        return <SuperuserBilling />;
      case 'Treatments':
        return <SuperuserTherapyTreatment />;
      case 'Blog':
        return <SuperuserBlogPosts />;
      case 'Subscriptions':
        return <SuperuserSubscriptions />;
      case 'ResourceCenters':
        return <SuperuserResourceCenters />;
      case 'Map':
        return <SuperuserClients />;
      case 'Chat':
        return <SuperuserClients />;
      case 'Assessments':
        return <SuperuserTherapyAssessments />;
      case 'Feedback':
        return <SuperuserTickets />;
      case 'OurShop':
        return <SuperuserClients />;
      case 'Calendar':
        return <SuperuserCalendars />;
      case 'Clients':
        return <SuperuserClients />;
      case 'UserAccounts':
        return <SuperuserUserAccounts />;
      case 'Settings':
        return <SuperuserSettings />;
      default:
        return renderIcons();
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {selectedComponent && (
          <Link underline="hover" color="inherit" onClick={() => setSelectedComponent(null)} style={{ cursor: 'pointer' }}>
            Home
          </Link>
        )}
        {selectedComponent && (
          <Typography color="text.primary">{selectedComponent}</Typography>
        )}
        {!selectedComponent && (
          <Typography color="text.primary">Home</Typography>
        )}
      </Breadcrumbs>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        renderComponent()
      )}
      {selectedComponent && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <IconButton onClick={() => setSelectedComponent(null)} sx={{ fontSize: 40, color: 'primary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)', color: 'secondary.main' } }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={editModalOpen}
        onClose={handleCloseEditModal}
      >
        <Box sx={{ ...modalStyle, width: '400px' }}>
          <Typography variant="h6" gutterBottom>Edit Item</Typography>
          <TextField
            label="Name"
            value={selectedItem ? selectedItem.name : ''}
            onChange={(e) => setSelectedItem({ ...selectedItem, name: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Phone"
            value={selectedItem ? selectedItem.phone : ''}
            onChange={(e) => setSelectedItem({ ...selectedItem, phone: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmitEdit}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
          </Button>
        </Box>
      </Modal>
      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
      >
        <Box sx={{ ...modalStyle, width: '400px' }}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>Are you sure you want to delete this item?</Typography>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSubmitDelete}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Delete'}
          </Button>
          <Button
            variant="outlined"
            fullWidth
            sx={{ mt: 1 }}
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default AdminInbox;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};
