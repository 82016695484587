import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon
} from "@mui/icons-material";
import axios from "axios";

const UserTreatments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [treatments, setTreatments] = useState([]);
  const [filteredTreatments, setFilteredTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchTreatments();
  }, []);

  const fetchTreatments = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/treatments");
      setTreatments(response.data);
      setFilteredTreatments(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch treatments", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredTreatments(
      treatments.filter(treatment =>
        treatment.provider.toLowerCase().includes(value) ||
        treatment.status.toLowerCase().includes(value) ||
        treatment.details.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (treatment) => {
    setSelectedTreatment(treatment);
  };

  const handleBackClick = () => {
    setSelectedTreatment(null);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Treatment/Medication</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTreatments.length > 0 ? (
            filteredTreatments.map((treatment) => (
              <TableRow key={treatment.id} onClick={() => handleRowClick(treatment)}>
                <TableCell>{new Date(treatment.date).toLocaleDateString()}</TableCell>
                <TableCell>{treatment.provider}</TableCell>
                <TableCell>{treatment.details}</TableCell>
                <TableCell>{treatment.status}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleRowClick(treatment)}>
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No treatments available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Treatment Details</Typography>
      <Typography variant="body1"><strong>Date:</strong> {new Date(selectedTreatment.date).toLocaleDateString()}</Typography>
      <Typography variant="body1"><strong>Provider:</strong> {selectedTreatment.provider}</Typography>
      <Typography variant="body1"><strong>Treatment/Medication:</strong> {selectedTreatment.details}</Typography>
      <Typography variant="body1"><strong>Status:</strong> {selectedTreatment.status}</Typography>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Your Treatments</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search treatments"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedTreatment ? (
        renderDetails()
      ) : (
        renderTable()
      )}
    </Container>
  );
};

export default UserTreatments;
