import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import axios from "axios";

const CorporateQuestionaire = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      // Replace the URL with your actual endpoint
      const response = await axios.get("https://therapy-api.accentrixcloud.com/questions");
      setQuestions(response.data);
      setFilteredQuestions(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch questions", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredQuestions(
      questions.filter(question =>
        question.question.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (question) => {
    setSelectedQuestion(question);
  };

  const handleBackClick = () => {
    setSelectedQuestion(null);
  };

  const handleOpenDeleteModal = (question) => {
    setSelectedQuestion(question);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedQuestion(null);
  };

  const handleDeleteSubmit = async () => {
    setLoading(true);
    try {
      await axios.delete(`https://therapy-api.accentrixcloud.com/questions/${selectedQuestion.id}`);
      fetchQuestions();
      handleCloseDeleteModal();
    } catch (error) {
      console.error("Failed to delete question", error);
      setLoading(false);
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.put(`https://therapy-api.accentrixcloud.com/questions/${selectedQuestion.id}`, selectedQuestion);
      fetchQuestions();
      handleBackClick();
    } catch (error) {
      console.error("Failed to edit question", error);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setSelectedQuestion({ ...selectedQuestion, [event.target.name]: event.target.value });
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell>Options</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredQuestions.length > 0 ? (
            filteredQuestions.map((question) => (
              <TableRow key={question.id} onClick={() => handleRowClick(question)}>
                <TableCell>{question.question}</TableCell>
                <TableCell>{question.options.join(", ")}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleRowClick(question); }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleOpenDeleteModal(question); }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No questionnaire records available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Question Details</Typography>
      <Box component="form" onSubmit={handleEditSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="question"
          label="Question"
          name="question"
          value={selectedQuestion.question || ""}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="options"
          label="Options (comma separated)"
          name="options"
          value={selectedQuestion.options.join(", ") || ""}
          onChange={(e) => handleChange({ target: { name: "options", value: e.target.value.split(", ") } })}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Questionnaire</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search questions"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedQuestion ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            Confirm Delete
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this question?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDeleteSubmit}
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default CorporateQuestionaire;
