import React, { useContext } from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { UserContext } from '../UserContext';

const UserMenu = ({ handleBillingViewClick, handleQuestionaireViewClick, handleDashboardViewClick, handleAppointmentsViewClick, handleAssessmentsViewClick, handleSessionsViewClick, handleTreatmentsViewClick, handlePostRecoveryViewClick, handleSubscriptionsViewClick }) => {
  const { user } = useContext(UserContext);

  return (
    <List>
      <ListItem button onClick={handleDashboardViewClick}>
        <ListItemText primary={`Dashboard - ${user.name}`} />
      </ListItem>
      <ListItem button onClick={handleBillingViewClick}>
        <ListItemText primary="Billing" />
      </ListItem>
      <ListItem button onClick={handleQuestionaireViewClick}>
        <ListItemText primary="Questionnaire" />
      </ListItem>
      <ListItem button onClick={handleAppointmentsViewClick}>
        <ListItemText primary="Appointments" />
      </ListItem>
      <ListItem button onClick={handleAssessmentsViewClick}>
        <ListItemText primary="Assessments" />
      </ListItem>
      <ListItem button onClick={handleSessionsViewClick}>
        <ListItemText primary="Sessions" />
      </ListItem>
      <ListItem button onClick={handleTreatmentsViewClick}>
        <ListItemText primary="Treatments" />
      </ListItem>
      <ListItem button onClick={handlePostRecoveryViewClick}>
        <ListItemText primary="Post Recovery" />
      </ListItem>
      <ListItem button onClick={handleSubscriptionsViewClick}>
        <ListItemText primary="Subscriptions" />
      </ListItem>
    </List>
  );
};

export default UserMenu;
