import React from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faCodepen,
  faFreeCodeCamp,
  faDev,
  faReact,
  faVuejs,
  faAngular,
  faNode,
  faWordpress,
  faAws,
  faDocker,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";

import "./IconMarquee.css";

const IconMarquee = () => {
  const icons = [
    faGithub,
    faCodepen,
    faFreeCodeCamp,
    faDev,
    faReact,
    faVuejs,
    faAngular,
    faNode,
    faWordpress,
    faAws,
    faDocker,
    faAndroid,
  ];

  return (
    <div className="marquee">
      <ul className="marquee-content" style={{ textAlign: "center" }}>
        <Typography
          variant="h5"
          style={{ marginTop: 20, textAlign: "center", color: "#3f51b5" }}
        >
          Our Partners
        </Typography>
        {icons.map((icon, index) => (
          <li key={index}>
            <FontAwesomeIcon icon={icon} size="lg" color="black" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IconMarquee;
