import React from "react";
import "./AnimatedText.css"; // Importing our CSS file

const AnimatedText = () => {
  return (
    <div className="animated-text-container">
      <span className="animated-text">Call Support +254 114 047 008</span>
    </div>
  );
};

export default AnimatedText;
