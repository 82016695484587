import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
  Button,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon } from "@mui/icons-material";
import axios from "axios";

const SuperuserSettings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [settings, setSettings] = useState([]);
  const [filteredSettings, setFilteredSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/accounts");
      setSettings(response.data);
      setFilteredSettings(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch settings data");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSettings(
      settings.filter(
        (setting) =>
          setting.contact_name.toLowerCase().includes(value) ||
          setting.email.toLowerCase().includes(value) ||
          setting.phone.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (setting) => {
    setSelectedSetting(setting);
    setFormData(setting);
    setOpenEditModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`https://therapy-api.accentrixcloud.com/accounts/${selectedSetting.id}`, formData);
      fetchSettings();
      setOpenEditModal(false);
    } catch (error) {
      setError("Failed to update settings data");
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Contact Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSettings.map((setting) => (
            <TableRow key={setting.id} onClick={() => handleRowClick(setting)}>
              <TableCell>{setting.contact_name}</TableCell>
              <TableCell>{setting.email}</TableCell>
              <TableCell>{setting.phone}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleRowClick(setting)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Profile Settings</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search settings"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredSettings.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No settings found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>Edit Setting</Typography>
          <Box component="form" onSubmit={handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="contact_name"
              label="Contact Name"
              name="contact_name"
              value={formData.contact_name || ""}
              onChange={(e) => setFormData({ ...formData, contact_name: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email || ""}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              value={formData.phone || ""}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={Boolean(error)} onClose={() => setError("")}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
          <Button onClick={() => setError("")} color="primary">
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserSettings;