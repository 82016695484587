import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import Navbar from "./components/Navbar";
import SupportLinks from "./components/SupportLinks";
import HealthCarousel from "./components/HealthCarousel";
import LoginDialog from "./components/LoginDialog";
import HomePage from "./pages/HomePage/HomePage";
import AdminProfile from "./components/AdminProfile";
import UserProfile from "./components/UserProfile";
import ServiceProvider from "./components/ServiceProvider";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Community from "./pages/Community";
import AboutUs from "./pages/AboutUs";
import MentalHealth from "./pages/MentalHealth";
import GeneralHealth from "./pages/GeneralHealth";
import Partner from "./pages/Partner";
import EmergencySupport from "./pages/EmergencySupport";
import ContactUs from "./components/ContactUs";
import AdminLogin from "./pages/AdminLogin";

import Corporates from "./pages/Corporates";
import SetPassword from "./components/SetPassword";
import SetSuperuserPassword from "./components/SetSuperuserPassword";
import ViewBilling from "./components/ViewBilling";
import ViewQuestionaire from "./components/ViewQuestionaire";
import ViewUserAppointments from "./components/ViewUserAppointments";
import ViewUserAssessment from "./components/ViewUserAssessment";
import ViewUserSessions from "./components/ViewUserSessions";
import ViewUserTreatments from "./components/ViewUserTreatments";
import ViewUserPostRecovery from "./components/ViewUserPostRecovery";
import ViewUserSubscriptions from "./components/ViewUserSubscriptions";
import CorporateProfile from "./components/CorporateProfile";
import ViewCorporateBilling from "./components/ViewCorporateBilling";
import ViewCorporateQuestionaire from "./components/ViewCorporateQuestionaire";
import ViewCorporatePostRecovery from "./components/ViewCorporatePostRecovery";
import ViewCorporateSubscriptions from "./components/ViewCorporateSubscriptions";
import ViewCorporateProfile from "./components/ViewCorporateProfile";
import ViewCorporateStaff from "./components/ViewCorporateStaff";
import ServiceProviderProfile from "./components/ServiceProviderProfile";
import ViewServiceProviderClients from "./components/ViewServiceProviderClients";
import ViewServiceProviderAppointments from "./components/ViewServiceProviderAppointments";
import ViewServiceProviderSessions from "./components/ViewServiceProviderSessions";
import ViewServiceProviderAssessments from "./components/ViewServiceProviderAssessments";
import ViewServiceProviderTreatment from "./components/ViewServiceProviderTreatment";
import ViewServiceProviderPostRecovery from "./components/ViewServiceProviderPostRecovery";
import ViewServiceProviderStaff from "./components/ViewServiceProviderStaff";
import ViewServiceProviderQuestionnaire from "./components/ViewServiceProviderQuestionnaire";
import ViewServiceProviderBills from "./components/ViewServiceProviderBills";
import ViewServiceProviderSettings from "./components/ViewServiceProviderSettings";
import ViewServiceProviderSubscriptions from "./components/ViewServiceProviderSubscriptions";
import ViewSuperuserAppointments from "./components/ViewSuperuserAppointments";
import ViewSuperuserSessions from "./components/ViewSuperuserSessions";
import ViewSuperuserTherapyTreatment from "./components/ViewSuperuserTherapyTreatment";
import ViewSuperuserPostRecovery from "./components/ViewSuperuserPostRecovery";
import ViewSuperuserTherapyAssessments from "./components/ViewSuperuserTherapyAssessments";
import ViewSuperuserUserAccounts from "./components/ViewSuperuserUserAccounts";
import ViewSuperuserTickets from "./components/ViewSuperuserTickets";
import ViewSuperuserQuestionnaires from "./components/ViewSuperuserQuestionnaires";
import ViewSuperuserSettings from "./components/ViewSuperuserSettings";
import ViewSuperuserResourceCenters from "./components/ViewSuperuserResourceCenters";
import ViewSuperuserBlogPosts from "./components/ViewSuperuserBlogPosts";
import ViewSuperuserCalendars from "./components/ViewSuperuserCalendars";
import ViewSuperuserSubscriptions from "./components/ViewSuperuserSubscriptions";
import ViewSuperuserBilling from "./components/ViewSuperuserBilling";
import ViewSuperuserClients from "./components/ViewSuperuserClients";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/view-appointments" element={<ViewUserAppointments />} />
          <Route path="/view-assessments" element={<ViewUserAssessment />} />
          <Route path="/view-sessions" element={<ViewUserSessions />} />
          <Route path="/view-treatments" element={<ViewUserTreatments />} />
          <Route path="/view-post-recovery" element={<ViewUserPostRecovery />} />
          <Route path="/view-subscriptions" element={<ViewUserSubscriptions />} />
          <Route path="/corporate-profile" element={<CorporateProfile />} />
          <Route path="/corporate-billing" element={<ViewCorporateBilling />} />
          <Route path="/corporate-questionaire" element={<ViewCorporateQuestionaire />} />
          <Route path="/corporate-staff" element={<ViewCorporateStaff />} />
          <Route path="/corporate-post-recovery" element={<ViewCorporatePostRecovery />} />
          <Route path="/corporate-subscriptions" element={<ViewCorporateSubscriptions />} />
          <Route path="/corporate-settings" element={<ViewCorporateProfile />} />
          <Route path="/service-dashboard" element={<ServiceProviderProfile />} />
          <Route path="/sp-clients" element={<ViewServiceProviderClients />} />
          <Route path="/sp-appointments" element={<ViewServiceProviderAppointments />} />
          <Route path="/sp-sessions" element={<ViewServiceProviderSessions />} />
          <Route path="/sp-assessments" element={<ViewServiceProviderAssessments />} />
          <Route path="/sp-treatment" element={<ViewServiceProviderTreatment />} />
          <Route path="/sp-post-recovery" element={<ViewServiceProviderPostRecovery />} />
          <Route path="/sp-staff" element={<ViewServiceProviderStaff />} />
          <Route path="/sp-questionnaire" element={<ViewServiceProviderQuestionnaire />} />
          <Route path="/sp-settings" element={<ViewServiceProviderSettings />} />
          <Route path="/sp-billing" element={<ViewServiceProviderBills />} />
          <Route path="/sp-subscriptions" element={<ViewServiceProviderSubscriptions />} />
          <Route path="/admin-profile" element={<AdminProfile />} />
          <Route path="/appointments" element={<ViewSuperuserAppointments />} />
          <Route path="/sessions" element={<ViewSuperuserSessions />} />
          <Route path="/treatment" element={<ViewSuperuserTherapyTreatment />} />
          <Route path="/post-recovery" element={<ViewSuperuserPostRecovery />} />
          <Route path="/assessments" element={<ViewSuperuserTherapyAssessments />} />
          <Route path="/user-accounts" element={<ViewSuperuserUserAccounts />} />
          <Route path="/tickets" element={<ViewSuperuserTickets />} />
          <Route path="/questionnaire" element={<ViewSuperuserQuestionnaires />} />
          <Route path="/settings" element={<ViewSuperuserSettings />} />
          <Route path="/resource-centers" element={<ViewSuperuserResourceCenters />} />
          <Route path="/blogposts" element={<ViewSuperuserBlogPosts />} />
          <Route path="/calendars" element={<ViewSuperuserCalendars />} />
          <Route path="/subscriptions" element={<ViewSuperuserSubscriptions />} />
          <Route path="/billing" element={<ViewSuperuserBilling />} />
          <Route path="/clients" element={<ViewSuperuserClients />} />
          <Route path="/blog" element={<Community />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/mental-health" element={<MentalHealth />} />
          <Route path="/service-providers" element={<ServiceProvider />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/resource-center" element={<EmergencySupport />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/corporates" element={<Corporates />} />
          <Route path="/macrobian" element={<AdminLogin />} />
          <Route path="/view-billing" element={<ViewBilling />} />
          <Route path="/view-questionaire" element={<ViewQuestionaire />} />
          <Route path="/contacts" element={<ContactUs />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/set-superuser-password" element={<SetSuperuserPassword />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
