import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Cancel as CancelIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';

const ServiceProviderTherapyTreatments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [treatments, setTreatments] = useState([]);
  const [filteredTreatments, setFilteredTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    date: '',
    name: '',
    status: '',
    treatmentDetails: ''
  });

  useEffect(() => {
    fetchTreatments();
  }, []);

  const fetchTreatments = async () => {
    try {
      const response = await axios.get('https://therapy-api.accentrixcloud.com/service-provider-treatments');
      setTreatments(response.data);
      setFilteredTreatments(response.data);
      setLoading(false);
    } catch (error) {
      handleError('Failed to fetch treatments');
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredTreatments(
      treatments.filter(
        (treatment) =>
          treatment.name.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenDetailsModal = (treatment) => {
    setSelectedTreatment(treatment);
    setFormData(treatment);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedTreatment(null);
    setFormData({
      date: '',
      name: '',
      status: '',
      treatmentDetails: ''
    });
  };

  const handleOpenAddEditModal = (treatment = null) => {
    setSelectedTreatment(treatment);
    setFormData(treatment || {
      date: '',
      name: '',
      status: '',
      treatmentDetails: ''
    });
    setOpenAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setOpenAddEditModal(false);
    setSelectedTreatment(null);
    setFormData({
      date: '',
      name: '',
      status: '',
      treatmentDetails: ''
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    try {
      if (selectedTreatment) {
        await axios.put(`/treatment/${selectedTreatment.id}`, formData);
      } else {
        await axios.post('/treatment', formData);
      }
      fetchTreatments();
      handleCloseAddEditModal();
    } catch (error) {
      handleError('Failed to save treatment');
    }
  };

  const handleDelete = async (treatmentId) => {
    try {
      await axios.delete(`/treatment/${treatmentId}`);
      fetchTreatments();
    } catch (error) {
      handleError('Failed to delete treatment');
    }
  };

  const handleCancel = async (treatmentId) => {
    try {
      await axios.put(`/treatment/${treatmentId}`, { status: 'Cancelled' });
      fetchTreatments();
    } catch (error) {
      handleError('Failed to cancel treatment');
    }
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTreatments.length > 0 ? (
            filteredTreatments.map((treatment) => (
              <TableRow key={treatment.id} onClick={() => handleOpenDetailsModal(treatment)}>
                <TableCell>{new Date(treatment.date).toLocaleString()}</TableCell>
                <TableCell>{treatment.name}</TableCell>
                <TableCell>{treatment.status}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleOpenAddEditModal(treatment); }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleCancel(treatment.id); }}>
                    <CancelIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(treatment.id); }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No treatments available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleCloseDetailsModal}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Treatment Details</Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="date"
          label="Date"
          name="date"
          type="datetime-local"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          required
          fullWidth
          id="treatmentDetails"
          label="Treatment Details"
          name="treatmentDetails"
          value={formData.treatmentDetails}
          onChange={handleChange}
          multiline
          rows={4}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Service Provider Therapy Treatments</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search treatments"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1, marginRight: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenAddEditModal()}
        >
          Add Treatment
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        renderTable()
      )}

      <Modal
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        sx={{ overflowY: 'auto' }}
      >
        <Box sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {renderDetails()}
        </Box>
      </Modal>

      <Modal
        open={openAddEditModal}
        onClose={handleCloseAddEditModal}
        sx={{ overflowY: 'auto' }}
      >
        <Box sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            {selectedTreatment ? 'Edit Treatment' : 'Add Treatment'}
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="date"
              label="Date"
              name="date"
              type="datetime-local"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              id="treatmentDetails"
              label="Treatment Details"
              name="treatmentDetails"
              value={formData.treatmentDetails}
              onChange={handleChange}
              multiline
              rows={4}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mt: 1 }}
              onClick={handleCloseAddEditModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorModal(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default ServiceProviderTherapyTreatments;
