import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  IconButton,
  Button
} from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, Edit as EditIcon } from '@mui/icons-material';
import axios from 'axios';

const CorporateSettings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [settings, setSettings] = useState([]);
  const [filteredSettings, setFilteredSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [formData, setFormData] = useState({
    contactName: '',
    email: '',
    phoneNumber: '',
    companyName: ''
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get('https://therapy-api.accentrixcloud.com/corporates');
      setSettings(response.data);
      setFilteredSettings(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch settings', error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSettings(
      settings.filter(
        (setting) =>
          setting.contactName.toLowerCase().includes(value) ||
          setting.email.toLowerCase().includes(value) ||
          setting.phoneNumber.toLowerCase().includes(value) ||
          setting.companyName.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenDetailsModal = (setting) => {
    setSelectedSetting(setting);
    setFormData(setting);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedSetting(null);
    setFormData({
      contactName: '',
      email: '',
      phoneNumber: '',
      companyName: ''
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`https://therapy-api.accentrixcloud.com/corporates/${selectedSetting.id}`, formData);
      fetchSettings();
      handleCloseDetailsModal();
    } catch (error) {
      console.error('Failed to update settings', error);
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Contact Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Company Name</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSettings.length > 0 ? (
            filteredSettings.map((setting) => (
              <TableRow key={setting.id} onClick={() => handleOpenDetailsModal(setting)}>
                <TableCell>{setting.contactName}</TableCell>
                <TableCell>{setting.email}</TableCell>
                <TableCell>{setting.phoneNumber}</TableCell>
                <TableCell>{setting.companyName}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDetailsModal(setting)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No corporate settings available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleCloseDetailsModal}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Setting Details</Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="contactName"
          label="Contact Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="phoneNumber"
          label="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          type="tel"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="companyName"
          label="Company Name"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Corporate Settings</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search settings"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedSetting ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openDetailsModal} onClose={handleCloseDetailsModal}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {renderDetails()}
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default CorporateSettings;
