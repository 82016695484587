import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Paper,
  Typography,
  Grid,
  Modal,
  Button,
  Breadcrumbs
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";

const navbarHeight = 64;

const bannerStyle = {
  display: "flex",
  flexDirection: "column",
  marginTop: `${navbarHeight}px`,
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: 'url("https://source.unsplash.com/random?therapy")',
  position: "relative",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const textStyle = {
  zIndex: 2,
  color: "white",
  fontSize: "48px",
  position: "relative",
};

const Terms = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <div style={{backgroundColor: "#e1f5fe"}}>
      <Navbar />
      <div style={bannerStyle}>
        <div style={overlayStyle}></div>
        <Typography variant="h3" style={textStyle}>
          Terms of Use
        </Typography>
      </div>
      <Breadcrumbs style={{padding: '20px'}} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Terms of Use</Typography>
        </Breadcrumbs>
      <Paper
        style={{
          padding: 20,
          backgroundColor: "#f5f5f5",
          color: "#000",
          margin: "20px auto",
          maxWidth: "800px"
        }}
      >

        <Typography variant="h6" gutterBottom>
          Terms of Use
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to our cloud-based therapy platform. By accessing and utilizing our services, you agree to comply with and be bound by the following terms and conditions.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. Account Registration
        </Typography>
        <Typography variant="body1" paragraph>
          Users must provide their name and email address for account registration, activation, and password setup. All information provided will be securely stored.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Assessment and Therapy Services
        </Typography>
        <Typography variant="body1" paragraph>
          Upon submission of the initial questionnaire, a licensed therapist will conduct an assessment. If therapy services are required, an appointment email will be sent to confirm the date and time. Users will be prompted for payment. For corporate users, the parent company will be billed per session.
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. Course of Treatment
        </Typography>
        <Typography variant="body1" paragraph>
          Assessments will determine the appropriate course of treatment. Users will receive regular email notifications for reminders, billing, newsletters, and other relevant communications.
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. Post-Treatment
        </Typography>
        <Typography variant="body1" paragraph>
          When no further sessions or treatments are needed and the patient is deemed recovered, the details will be moved to post-recovery, and a congratulatory message will be sent to the user.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. Marketing and Awareness
        </Typography>
        <Typography variant="body1" paragraph>
          We utilize content, marketing materials, and blog posts to raise awareness. Users may share their experiences on social media.
        </Typography>
        <Typography variant="h6" gutterBottom>
          6. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          Contact details for therapy services and resources across the country will be available on the platform, with a map displaying locations for booking appointments.
        </Typography>
        <Typography variant="h6" gutterBottom>
          7. Security
        </Typography>
        <Typography variant="body1" paragraph>
          All information provided by users is securely stored in compliance with data protection regulations.
        </Typography>
        <Typography variant="h6" gutterBottom>
          8. General
        </Typography>
        <Typography variant="body1" paragraph>
          All rights reserved © {new Date().getFullYear()} Macrobian Health. By using this service, you agree to these terms and any changes posted subsequently.
        </Typography>
       
      </Paper>

      <Paper
        style={{
          padding: 20,
          backgroundColor: "#e1f5fe",
          color: "#FFF",
        }}
      >
        <Grid container spacing={2} mt={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#7986cb" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#7986cb" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy-policy"
              >
                Privacy
              </Typography>
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/terms"
              >
                Terms of Use
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Terms;
