import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  Box,
  Modal,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmbulance, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Map from "./Map"; // Import the Map component
import axios from "axios";
import logo from "../assets/images/logos/logo.jpg"; // Adjust the path to your logo file

const QuickLinks = () => {
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResources, setFilteredResources] = useState([]);
  const [resources, setResources] = useState([]);
  const [resourceCenters, setResourceCenters] = useState([]); // Initialize as an empty array
  const [error, setError] = useState("");
  const [openManageModal, setOpenManageModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    fetchResourceCenters();
  }, []);

  useEffect(() => {
    if (selectedLink?.center === "Resource Centers") {
      setFilteredResources(resources);
    }
  }, [selectedLink, resources]);

  const fetchResourceCenters = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://therapy-api.accentrixcloud.com/api/resource-centres");
      setResourceCenters(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch resource centers data");
      setLoading(false);
    }
  };

  const fetchResources = async (centerId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://therapy-api.accentrixcloud.com/api/resource-centres/${centerId}/resources`);
      setResources(response.data);
      setFilteredResources(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch resources data");
      setLoading(false);
    }
  };

  const handleOpen = (link) => {
    setSelectedLink(link);
    setOpen(true);
    if (link.name === "Talk to an Expert") {
      fetchResources(link.id); // Fetch resources for "Talk to an Expert"
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedLink(null);
    setSelectedResource(null);
    setFormData({ name: '', email: '' }); // Reset form data on close
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value === "") {
      setFilteredResources(resources);
    } else {
      const filtered = resources.filter(resource =>
        resource.name.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setFilteredResources(filtered);
    }
  };

  const handleSubmit = async (endpoint) => {
    setLoading(true);
    try {
      await axios.post(endpoint, formData);
      alert("Submission successful!");
      handleClose();
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      alert("Submission failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const links = [
    {
      name: "Therapists Near Me",
      icon: <HelpOutlineIcon style={{ color: "coral" }} />,
      description: "Find Therapists Near You",
      showMap: true,
    },
    {
      name: "Resource Centers",
      icon: <FontAwesomeIcon icon={faAmbulance} style={{ color: "coral" }} />,
      description: "Help and support centers around the country",
      contacts: [
        "Child Helpline: 254722178177",
        "Depression Helpline: 123-456-7890",
        "Suicide Helpline: 987-654-3210",
      ],
      showTable: true,
    },
    {
      name: "Talk to an Expert",
      icon: <SupportAgentIcon style={{ color: "coral" }} />,
      description: "Support line: +254 722 178 177",
      showTable: true,
      id: 1 // Provide a suitable ID for fetching resources
    },
    {
      name: "Signup for Our Newsletter",
      icon: <FontAwesomeIcon icon={faEnvelope} style={{ color: "coral" }} />,
      description: "Get our monthly newsletter",
      showForm: true,
    },
  ];

  return (
    <Box
      sx={{
        borderLeft: 4,
        borderColor: "coral",
        pl: 2,
      }}
    >
      <Typography variant="h6" sx={{ color: "#3f51b5" }}>
        Quick Links
      </Typography>
      <List>
        {links.map((link, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={() => handleOpen(link)}>
              <ListItemIcon sx={{ color: "coral" }}>{link.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ color: "#3f51b5" }}>{link.name}</Typography>
                }
                secondary={
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {link.description}
                  </Typography>
                }
              />
            </ListItem>
            {index < links.length - 1 && <Divider sx={{ bgcolor: "coral" }} />}
          </React.Fragment>
        ))}
      </List>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            overflowY: "auto",
            maxHeight: "80vh",
            textAlign: "center",
          }}
        >
          <img src={logo} alt="Logo" style={{ marginBottom: '20px', height: '50px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            {selectedLink?.center}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 2 }}>
            {selectedLink?.description}
          </Typography>
          {error && (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          {selectedLink?.tel && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>Contacts:</Typography>
              {selectedLink.tel.map((contact, index) => (
                <Typography key={index} sx={{ mb: 0.5 }}>
                  {contact}
                </Typography>
              ))}
            </Box>
          )}
          {selectedLink?.showMap && (
            <Map
              resourceCenters={resourceCenters || []} // Pass an empty array if resourceCenters is undefined
              userLocation={selectedResource ? { lat: selectedResource.latitude, lng: selectedResource.longitude, name: selectedResource.center } : null}
            />
          )}
          {selectedLink?.showTable && (
            <Box>
              {selectedLink.center === "Resource Centers" && (
                <Box>
                  <TextField
                    label="Find Your Expert"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{ mb: 2 }}
                  />
                  <Button variant="contained" onClick={() => fetchResources(selectedLink.id)} sx={{ backgroundColor: "coral", mb: 2 }}>
                    Search
                  </Button>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Resource Center</TableCell>
                          <TableCell>Tel</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell>Specialization</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredResources.map((resource, index) => (
                          <TableRow key={index}>
                            <TableCell>{resource.center}</TableCell>
                            <TableCell>{resource.tel}</TableCell>
                            <TableCell>{resource.location}</TableCell>
                            <TableCell>{resource.specialization}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              {selectedLink.center === "Talk to an Expert" && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Specialization</TableCell>
                        <TableCell>Contact</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resources.map((resource, index) => (
                        <TableRow key={index}>
                          <TableCell>{resource.name}</TableCell>
                          <TableCell>{resource.specialization}</TableCell>
                          <TableCell>{resource.contact}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
          {selectedLink?.showForm && (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}
            >
              <TextField required id="name" label="Name" name="name" value={formData.name} onChange={handleChange} sx={{ mb: 2 }} />
              <TextField required id="email" label="Email" name="email" value={formData.email} onChange={handleChange} type="email" sx={{ mb: 2 }} />
              <Button variant="contained" onClick={() => handleSubmit('/api/users')} sx={{ backgroundColor: "coral", mt: 2 }}>
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Box>
          )}
          {!selectedLink?.showMap && !selectedLink?.showTable && !selectedLink?.showForm && (
            <Button variant="contained" onClick={handleClose} sx={{ mt: 2 }}>
              Close
            </Button>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default QuickLinks;
