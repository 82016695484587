import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import IconMarquee from "./IconMarquee";
import Navbar from "./Navbar";
import SocialMediaLinks from "./SocialMediaLinks";


import AdminMenu from "./AdminMenu";

import SuperuserSettings from "./SuperuserSettings";
import SuperuserResourceCenters from "./SuperuserResourceCenters";

const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const services = [
    { name: "Inbox", description: "Your central place for notifications and messages.", icon: "mail", path: "/inbox" },
    { name: 'Consultations', description: 'Paid outside work', icon: 'people_outline', path: '/consultations' },
    { name: 'Observations', description: 'Internal work clients pay for', icon: 'visibility', path: '/observations' },
    { name: 'Referrals', description: 'Refer to lab, radiology, dentist, etc.', icon: 'send', path: '/referrals' },  
  {
    name: "Therapy",
    description: "Professional counselling services.",
    icon: "psychology",
    path: "/therapy",
  },
  {
    name: "Pharmacy",
    description: "Pharmacy services for prescriptions.",
    icon: "local_pharmacy",
    path: "/pharmacy",
  },
 
  { name: "Our Team", description: "Meet your dedicated team of healthcare professionals.", icon: "group", path: "/our-team" },
  { name: "Integrations", description: "Seamlessly integrate with other healthcare systems.", icon: "integration_instructions", path: "/integrations" },
  // New services added
  { name: "Financials", description: "Overview of financial operations and billing.", icon: "attach_money", path: "/finances" },
  { name: "Master Data", description: "Manage and access critical master data.", icon: "data_object", path: "/master-data" },
  { name: "Appointments", description: "Schedule and manage appointments efficiently.", icon: "event_available", path: "/appointments" },
  { name: "CRM", description: "Manage customer relationships and interactions.", icon: "people_alt", path: "/crm" },
  // Adding new services
  { name: "Crowdfunding", description: "Support and initiate healthcare crowdfunding campaigns.", icon: "volunteer_activism", path: "/crowdfunding" },
  { name: "Polls", description: "Engage with polls to make informed decisions.", icon: "poll", path: "/polls" },


  // Add more services as needed
];

const ViewSuperuserResourceCenters = () => {


  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleClientsViewClick = () => {
    navigate('/clients');
};

  const handleBillingViewClick = () => {
    navigate('/billing');
};

  const handleDashboardViewClick = () => {
    navigate('/admin-profile');
};

const handleQuestionaireViewClick = () => {
    navigate('/questionnaire');
};

const handleAccountsViewClick = () => {
  navigate('/user-accounts');
};

const handlePostRecoveryViewClick = () => {
  navigate('/post-recovery');
};

const handleSubscriptionsViewClick = () => {
  navigate('/subscriptions');
};

const handleProfileViewClick = () => {
  navigate('/settings');
};

const handleAppointmentsViewClick = () => {
    navigate('/appointments');
  };

  const handleAssessmentsViewClick = () => {
    navigate('/assessments');
  }; 

  const handleSessionsViewClick = () => {
    navigate('/sessions');
  };

  const handleTreatmentsViewClick = () => {
    navigate('/treatment');
  };

  const handleTicketsViewClick = () => {
    navigate('/tickets');
  };
  
  const handleResourceCentersViewClick = () => {
    navigate('/resource-centers');
  };

  const handleBlogPostsViewClick = () => {
    navigate('/blogposts');
  };

  const handleCalendarsViewClick = () => {
    navigate('/calendars');
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Navbar />
      <Paper
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          marginTop: `${navbarHeight}px`,
          padding: 20,
          backgroundColor: "#1B277D",
          color: "#FFF",
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex", height: "calc(100vh - 64px)" }}>
          <Box
            sx={{
              width: { xs: 0, sm: "200px" }, // Adjust width as needed
              overflowY: "auto",
            }}
          >
            {/* Dashboard and TreeView go here */}
            <Typography variant="h6" style={{ padding: "16px" }}>
              Dashboard
            </Typography>
            {/* TreeView component */}
            <AdminMenu handleClientsViewClick={handleClientsViewClick} handleCalendarsViewClick={handleCalendarsViewClick} handleBlogPostsViewClick={handleBlogPostsViewClick} handleResourceCentersViewClick={handleResourceCentersViewClick} handleTicketsViewClick={handleTicketsViewClick} handleTreatmentsViewClick={handleTreatmentsViewClick} handleSessionsViewClick={handleSessionsViewClick} handleAssessmentsViewClick={handleAssessmentsViewClick} handleAppointmentsViewClick={handleAppointmentsViewClick}  handleProfileViewClick={handleProfileViewClick} handleSubscriptionsViewClick={handleSubscriptionsViewClick} handlePostRecoveryViewClick={handlePostRecoveryViewClick} handleAccountsViewClick={handleAccountsViewClick} handleQuestionaireViewClick={handleQuestionaireViewClick} handleBillingViewClick={handleBillingViewClick} handleDashboardViewClick={handleDashboardViewClick}   />

          </Box>
          <Box sx={{ flex: 1, overflowY: "auto" }}>
            {/* Main content goes here */}
            <Paper
              elevation={3}
              style={{
                margin: "16px",
                padding: "16px",
                flexGrow: 1,
                overflow: "auto",
                height: "calc(100vh - 180px - 32px)",
                backgroundColor: "#b2dfdb",
              }}
            >
              <SuperuserResourceCenters />
            </Paper>
          </Box>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#00e5ff" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#fff" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ViewSuperuserResourceCenters;
